import { Stack } from "@mui/material";
import { useCallback, useMemo } from "react";
import ScrollableFlexContainer from "../../../../../shared/components/ScrollableFlexContainer";
import { FieldConfiguration, ReportField } from "../../../../../shared/reporting/api/biClient.types";
import MeasureFieldsContainer from "../../common/fields/format/MeasureFieldsContainer";
import { useFieldsStateContext } from "../contexts/FieldsStateContext";
import TextFieldContainer from "./TextFieldsContainer";
import { isDimensionBased, isMeasure } from "../../utils/fieldsHelper";
import DimensionFormattingContainer from "../../common/fields/format/DimensionFormattingContainer";
import { canApplyFormat } from "../../common/fields/format/dimensionFormattingHelper";

export default function FormatContainer() {
  const { argumentsArea, valuesArea } = useFieldsStateContext();
  const supportingFormattingMeasures = useMemo(() => valuesArea.values.filter(isMeasure), [valuesArea.values]);
  const supportingFormattingDimensions = useMemo(
    () => valuesArea.values.filter(isDimensionBased).filter(canApplyFormat),
    [valuesArea.values]
  );
  const handleMeasureChanged = useCallback(
    (measure: ReportField, changes: Partial<FieldConfiguration>) => {
      const field = valuesArea.values.find((f) => f.config.guid === measure.config.guid);
      if (field !== undefined) {
        valuesArea.updateItemConfig(field, changes);
      }
    },
    [valuesArea]
  );

  const handleDimensionChange = useCallback(
    (dimension: ReportField, changes: Partial<FieldConfiguration>) => {
      const field = valuesArea.values.find((f) => f.config.guid === dimension.config.guid);
      if (isDimensionBased(field)) {
        valuesArea.updateItemConfig(field, changes);
      }
    },
    [valuesArea]
  );

  return (
    <ScrollableFlexContainer>
      <Stack gap={1} mx={"1.3rem"}>
        <TextFieldContainer fields={argumentsArea.values} onUpdateConfig={argumentsArea.updateItemConfig} />
        <DimensionFormattingContainer
          dimensions={supportingFormattingDimensions}
          onUpdateConfig={handleDimensionChange}
        />
        <MeasureFieldsContainer measures={supportingFormattingMeasures} onChange={handleMeasureChanged} />
      </Stack>
    </ScrollableFlexContainer>
  );
}
