import { Box, Grid, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useCallback } from "react";
import { AmountType } from "../../../../../shared/reporting/api/biClient.types";
import { toAmountTypeControlCaption } from "../customMeasure/utilities/measureConverter";

interface Props {
  amountType: AmountType;
  disabled?: boolean;
  onChange: (amountType: AmountType) => void;
}

const AmountTypeFieldControl = ({ amountType, disabled, onChange }: Props) => {
  const handleAmountTypeChange = useCallback(
    (_: React.MouseEvent<HTMLElement>, newAmountType: AmountType | null) => {
      if (newAmountType !== null) onChange(newAmountType);
    },
    [onChange]
  );

  return (
    <Grid container sx={{ alignItems: "center", justifyContent: "space-between" }}>
      <Box sx={{ display: "flex", flexGrow: 1, flexBasis: "30%" }}>
        <Typography noWrap>Amount Type</Typography>
      </Box>
      <ToggleButtonGroup
        exclusive
        color="primary"
        value={amountType}
        disabled={disabled}
        fullWidth
        sx={{ width: 208 }}
        onChange={handleAmountTypeChange}
      >
        {Object.values(AmountType).map((type, i) => (
          <ToggleButton key={i} value={type} sx={{ py: 0.3, fontWeight: 400 }}>
            {toAmountTypeControlCaption(type)}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Grid>
  );
};

export default AmountTypeFieldControl;
