import {
  DimensionField,
  FieldConfiguration,
  FieldConfigurationType,
  MeasureField,
  ReportField,
} from "../../../../shared/reporting/api/biClient.types";
import { Maybe } from "../../../../shared/types";
import { getDefaultFormatting } from "./aggregationHelper";

export const isMeasure = (field: ReportField | undefined | null): field is MeasureField => {
  if (field === undefined || field === null) {
    return false;
  }
  return field.config.type === FieldConfigurationType.Irr || field.config.type === FieldConfigurationType.Measure;
};

export const isAggregation = (field: ReportField | undefined | null): boolean => {
  return isAggregatedType(field?.config);
};

export const isAggregatedType = (config: Maybe<Partial<FieldConfiguration>>) => {
  return config?.type === FieldConfigurationType.Aggregation;
};

export const isNotAggregatedType = (config: Maybe<Partial<FieldConfiguration>>) => {
  return config?.type !== FieldConfigurationType.Aggregation || config.aggregation === undefined;
};

export const isAggregationBased = (field: ReportField | undefined | null): field is MeasureField => {
  return isMeasure(field) || isAggregation(field);
};

export const isDimension = (field: ReportField | undefined | null): field is DimensionField => {
  return field?.config?.type === FieldConfigurationType.Dimension;
};

export const isNotAggregatedDimension = (field: Maybe<ReportField>): field is DimensionField => {
  return isDimension(field) && isNotAggregatedType(field.config);
};

export const isDimensionBased = (field: ReportField | undefined | null): field is DimensionField => {
  if (field === undefined || field === null) {
    return false;
  }
  return isDimensionBasedType(field.config.type);
};

export const isDimensionBasedType = (type: FieldConfigurationType) => {
  return type === FieldConfigurationType.Dimension || type === FieldConfigurationType.Aggregation;
};

export const getMeasures = (fields: ReportField[]): ReportField[] => {
  return fields.filter(isMeasure);
};

export const getDimensions = (fields: ReportField[]): DimensionField[] => {
  return fields.filter(isDimensionBased);
};

export const getAggregationBasedFields = (fields: ReportField[]) => {
  return fields.filter((f) => isAggregationBased(f));
};

export const ensureDefaultFormatIsSet = (field: ReportField): ReportField => {
  if (!field.meta.defaultFormat && isDimensionBased(field)) {
    return {
      ...field,
      meta: {
        ...field.meta,
        defaultFormat: getDefaultFormatting(field),
      },
    };
  }

  return field;
};
