import { useCallback } from "react";
import { FieldConfiguration, Grouping, ItemDataType } from "../../../../../../shared/reporting/api/biClient.types";
import CustomLabel from "../../../../common/customLabel/CustomLabel";
import FieldOptionDeleteButton from "../../../../common/FieldOptionDeleteButton";
import FieldOptionsDivider from "../../../../common/FieldOptionsDivider";
import FieldPopover from "../../../common/fields/FieldPopover";
import { GeneralFieldOptionPopupProps } from "../../../common/fields/types/generalFieldOptionPopup.types";
import SuppressBlankValues from "../../../common/SuppressBlankValues";
import DateGroupingComponent from "../../../tabular/table/DateGroupingComponent";
import { referenceDateFields } from "../../../tabular/utilities/referenceDateFields";

interface Props extends GeneralFieldOptionPopupProps {
  supportedDateGroupings?: (Grouping | undefined)[];
}

export default function GeneralFieldOptionPopup({
  anchorEl,
  field,
  supportedDateGroupings,
  cancel,
  onUpdateConfiguration,
  onRemove,
  onUpdateCustomLabel,
}: Props) {
  const isDateField = field.meta.type === ItemDataType.Date;
  const isNumeric = field.meta.type === ItemDataType.Numeric;
  const showGroupings = referenceDateFields.includes(field.meta.name);

  const updateGrouping = useCallback(
    (newGrouping: Grouping | undefined) => {
      if (field.config.grouping !== newGrouping) {
        onUpdateConfiguration({ grouping: newGrouping }, true);
      }
    },
    [field.config.grouping, onUpdateConfiguration]
  );

  const handleSuppressValues = useCallback(
    (changes: Partial<FieldConfiguration>) => {
      onUpdateConfiguration(changes, false);
    },
    [onUpdateConfiguration]
  );

  const handleOnRemove = useCallback(() => {
    onRemove();
    cancel();
  }, [onRemove, cancel]);

  return (
    <FieldPopover open={!!anchorEl} anchorEl={anchorEl} onClose={cancel} sx={{ width: 400, py: 0 }}>
      <CustomLabel
        customLabelValue={field.config.customLabel}
        originalName={field.meta.caption}
        updateCustomLabel={onUpdateCustomLabel}
      />
      <FieldOptionsDivider />
      {isDateField && showGroupings && (
        <DateGroupingComponent
          field={field}
          supportedDateGroupings={supportedDateGroupings || defaultDateGroupingForColumns}
          updateGrouping={updateGrouping}
        />
      )}
      {!isDateField && (
        <SuppressBlankValues
          field={field}
          onChanged={handleSuppressValues}
          showExcludeZeroValues={field.config.hideBlankValues === true && isNumeric}
        />
      )}
      <FieldOptionsDivider />
      <FieldOptionDeleteButton onRemove={handleOnRemove} />
    </FieldPopover>
  );
}

const defaultDateGroupingForColumns = [Grouping.ByYear, Grouping.ByQuarter, Grouping.ByMonth];
