import { Grouping } from "../../../../../../shared/reporting/api/biClient.types";
import { GeneralFieldOptionPopupProps } from "../../../common/fields/types/generalFieldOptionPopup.types";
import GeneralFieldOptionPopup from "../columns/GeneralFieldOptionPopup";

const RowFieldOptionPopup = (props: GeneralFieldOptionPopupProps) => {
  return (
    <GeneralFieldOptionPopup
      {...props}
      supportedDateGroupings={[Grouping.ByYear, Grouping.ByQuarter, Grouping.ByMonth, undefined]}
    />
  );
};

export default RowFieldOptionPopup;
