import { useCallback } from "react";
import { DimensionField } from "../../../../../shared/reporting/api/biClient.types";
import { GroupingArea } from "../../tabular/contexts/FieldsStateContext";
import SelectFieldInput from "./SelectFieldInput";

interface Props {
  area: GroupingArea;
  availableFields: DimensionField[];
}

export const SelectGroupFieldInput = ({ area, availableFields }: Props) => {
  const addFieldToGroup = useCallback((field: DimensionField) => area.addItem({ name: field.config.guid }), [area]);

  return <SelectFieldInput onAddField={addFieldToGroup} availableFields={availableFields} />;
};

export default SelectGroupFieldInput;
