import { Collapse, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { PropsWithChildren, useState } from "react";
import HorizontalFill from "../../../../../shared/components/HorizontalFill";
import ExpandCollapseButton from "./ExpandCollapseButton";

export default function StyleGroup({ caption, children }: PropsWithChildren<{ caption: string }>) {
  const [expanded, setExpanded] = useState(true);

  return (
    <Grid container sx={{ flexDirection: "column" }}>
      <Grid container sx={{ alignItems: "center", height: "36px", px: "1.3rem", bgcolor: grey[100] }}>
        <Typography variant="subtitle1">{caption}</Typography>
        <HorizontalFill />
        <ExpandCollapseButton expanded={expanded} onChange={setExpanded} />
      </Grid>
      <Collapse in={expanded} sx={{ mx: "1.3rem", my: 2 }}>
        {children}
      </Collapse>
    </Grid>
  );
}
