import { createContext } from "react";
import {
  DimensionField,
  FieldConfiguration,
  PivotGeneralSettings,
  ReportField,
  SortConfiguration,
} from "../../../../shared/reporting/api/biClient.types";
import { SortField } from "../Types";
import { ConditionFieldsArea, FieldArea } from "../common/fields/FieldArea";

export type RowFieldsArea = FieldArea<DimensionField, FieldConfiguration> & {
  addItem: (item: DimensionField, index: number) => void;
};

export type ColumnFieldsArea = FieldArea<DimensionField, FieldConfiguration> & {
  addItem: (item: DimensionField, index: number) => void;
};

export type ValueFieldsArea = FieldArea<ReportField, FieldConfiguration> & {
  addItem: (item: ReportField, index: number) => void;
};

export type SortingFieldsArea = FieldArea<SortField, SortConfiguration> & {
  addItem: (item: DimensionField, isGroupField: boolean, groupMetaNames: string[]) => void;
  setSorting: (fields: SortField[], groupMetaNames: string[]) => void;
};

export type GeneralSettingsArea = {
  settings: PivotGeneralSettings;
  update: (changes: Partial<PivotGeneralSettings>) => void;
};

export type BuilderContextType = {
  conditionsArea: ConditionFieldsArea;
  rowsArea: RowFieldsArea;
  columnsArea: ColumnFieldsArea;
  valuesArea: ValueFieldsArea;
  sortArea: SortingFieldsArea;
  settingsArea: GeneralSettingsArea;
};

export const BuilderContext = createContext<BuilderContextType>({} as BuilderContextType);
