import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  AxisSettings,
  BasicLinearSettings,
  ChartStyleSettings,
  ConditionConfiguration,
  DimensionField,
  FieldConfiguration,
  LegendSettings,
  LinearSeriesSettings,
  ReportField,
  ReportType,
  SeriesSettings,
  ValueAxisType,
} from "../../../../../shared/reporting/api/biClient.types";
import { insertItemAt } from "../../../../utilities/Utilities";
import {
  calculateAndAssignSystemLabel,
  moveConditionItem,
  moveMeasureItem,
  removeConditionFromLinked,
  removeConditionItem,
  removeMeasureItem,
  updateConditionConfig,
  updateConditionItem,
  updateLinkAndValidate,
} from "../../common/utilities/fieldsState";
import { ConditionField } from "../../Types";
import { isDimensionBased } from "../../utils/fieldsHelper";
import { getDefaultSerieType, getLinearSettings } from "../contexts/FieldsStateContext.types";
import { removeSerie, validateStateByReportType } from "../utilities/seriesUtils";
import updateValueAxis from "../utilities/updateValueAxis";
import { FieldsState } from "./FieldsState";

const initialState: FieldsState = {
  conditions: [],
  arguments: [],
  values: [],
  settings: { type: ReportType.BarChart },
};

const fieldsStateChartSlice = createSlice({
  name: "fieldsStateChart",
  initialState,
  reducers: {
    setConditionsAction: (state, action: PayloadAction<ConditionField[]>) => {
      state.conditions = action.payload;
      state.conditions = updateLinkAndValidate(state.conditions, state.values);
    },
    addConditionAction: (state, action: PayloadAction<{ field: ConditionField; index: number }>) => {
      const condition: ConditionField = { ...action.payload.field };
      calculateAndAssignSystemLabel(condition, state.conditions);
      const conditions = insertItemAt(state.conditions, condition, action.payload.index);
      state.conditions = updateLinkAndValidate(conditions, state.values);
    },
    removeConditionAction: (state, action: PayloadAction<ConditionField>) => {
      const conditions = removeConditionItem(action.payload, state.conditions);
      removeConditionFromLinked(action.payload.config.guid, state.values);
      state.conditions = updateLinkAndValidate(conditions, state.values);
    },
    moveConditionAction: (state, action: PayloadAction<{ field: ConditionField; newIndex: number }>) => {
      state.conditions = moveConditionItem(action.payload.field, action.payload.newIndex, state.conditions);
      state.conditions = updateLinkAndValidate(state.conditions, state.values);
    },
    updateConditionAction: (
      state,
      action: PayloadAction<{ field: ConditionField; changes: Partial<ConditionField> }>
    ) => {
      state.conditions = updateConditionItem(action.payload.field, action.payload.changes, state.conditions);
    },
    updateConditionConfigAction: (
      state,
      action: PayloadAction<{ field: ConditionField; changes: Partial<ConditionConfiguration> }>
    ) => {
      const condition = state.conditions.find((c) => c.config.guid === action.payload.field.config.guid);
      if (condition) {
        condition.config = updateConditionConfig(condition.config, action.payload.changes);
      }
    },
    setArgumentsAction: (state, action: PayloadAction<DimensionField[]>) => {
      state.arguments = action.payload;
    },
    addArgumentAction: (state, action: PayloadAction<{ field: DimensionField; index: number }>) => {
      state.arguments = insertItemAt(state.arguments, action.payload.field, action.payload.index);
    },
    removeArgumentAction: (state, action: PayloadAction<DimensionField>) => {
      const fieldIndex = state.arguments.findIndex((a) => a.config.name === action.payload.config.name);
      if (fieldIndex > -1) {
        const copy = [...state.arguments];
        copy.splice(fieldIndex, 1);
        state.arguments = copy;
      }
    },
    moveArgumentAction: (state, action: PayloadAction<{ field: DimensionField; newIndex: number }>) => {
      const fieldIndex = state.arguments.findIndex((a) => a.config.name === action.payload.field.config.name);
      if (fieldIndex > -1) {
        const copy = [...state.arguments];
        copy.splice(fieldIndex, 1);
        copy.splice(action.payload.newIndex, 0, action.payload.field);
        state.arguments = copy;
      }
    },
    updateArgumentAction: (
      state,
      action: PayloadAction<{ field: DimensionField; changes: Partial<DimensionField> }>
    ) => {
      const index = state.arguments.findIndex((a) => a.config.name === action.payload.field.config.name);
      const updatedField = { ...action.payload.field, ...action.payload.changes };
      if (index > -1) {
        state.arguments[index] = updatedField;
      }
    },
    updateArgumentConfigAction: (
      state,
      action: PayloadAction<{ field: DimensionField; changes: Partial<FieldConfiguration> }>
    ) => {
      const argument = state.arguments.find((c) => c.config.name === action.payload.field.config.name);
      if (isDimensionBased(argument)) {
        argument.config = { ...argument.config, ...action.payload.changes };
      }
    },
    setMeasuresAction: (state, action: PayloadAction<ReportField[]>) => {
      state.values = action.payload;
      state.conditions = updateLinkAndValidate(state.conditions, action.payload);
    },
    addMeasureAction: (state, action: PayloadAction<{ field: ReportField; index: number }>) => {
      state.values = insertItemAt(state.values, action.payload.field, action.payload.index);
    },
    removeMeasureAction: (state, action: PayloadAction<ReportField>) => {
      const measures = removeMeasureItem(action.payload, state.values);
      state.values = measures;
      state.conditions = updateLinkAndValidate(state.conditions, measures);
    },
    moveMeasureAction: (state, action: PayloadAction<{ field: ReportField; newIndex: number }>) => {
      state.values = moveMeasureItem(action.payload.field, action.payload.newIndex, state.values);
    },
    updateMeasureAction: (state, action: PayloadAction<{ field: ReportField; changes: Partial<ReportField> }>) => {
      const field = state.values.find((v) => v.config.guid === action.payload.field.config.guid);
      if (field !== undefined) {
        const index = state.values.indexOf(field);
        if (index > -1) {
          const updatedField = { ...field, ...action.payload.changes } as ReportField;
          state.values[index] = updatedField;
        }
      }
    },
    updateMeasureConfigAction: (
      state,
      action: PayloadAction<{ field: ReportField; changes: Partial<FieldConfiguration> }>
    ) => {
      const measure = state.values.find((c) => c.config.guid === action.payload.field.config.guid);
      if (measure) {
        measure.config = { ...measure.config, ...action.payload.changes };
        state.conditions = updateLinkAndValidate(state.conditions, state.values);
      }
    },
    setSetingsAction: (state, action: PayloadAction<ChartStyleSettings>) => {
      state.settings = { ...state.settings, ...action.payload };
    },
    updateSettingsAction: (state, action: PayloadAction<Partial<ChartStyleSettings>>) => {
      state.settings = { ...state.settings, ...action.payload };
      state.settings = validateStateByReportType(state);
    },
    updateReportTypeSettingsAction: (state, action: PayloadAction<ReportType>) => {
      state.settings.type = action.payload;
      state.settings = validateStateByReportType(state);
    },
    updateLegendSettingsAction: (state, action: PayloadAction<Partial<LegendSettings>>) => {
      state.settings.legend = { ...state.settings.legend, ...action.payload };
    },
    updateAxisSettingsAction: (state, action: PayloadAction<Partial<AxisSettings>>) => {
      const settings = state.settings as BasicLinearSettings;
      settings.argumentAxis = { ...settings.argumentAxis, ...action.payload };
    },
    updateAxisArgumentSettingsAction: (state, action: PayloadAction<Partial<AxisSettings>>) => {
      const settings = state.settings as BasicLinearSettings;
      settings.argumentAxis = { ...settings.argumentAxis, ...action.payload };
    },
    updateAxisValueSettingsAction: (state, action: PayloadAction<{ name: string; changes: Partial<AxisSettings> }>) => {
      const settings = state.settings as BasicLinearSettings;
      let item = settings.valueAxis?.find((va) => va.name === action.payload.name);
      if (settings.valueAxis !== undefined && item !== undefined) {
        const index = settings.valueAxis?.findIndex((va) => va.name === item?.name);
        if (index !== undefined && index > -1) {
          item = { ...item, ...action.payload.changes };
          settings.valueAxis[index] = item;
        }
      }
    },
    addSeriesAction: (state, action: PayloadAction<string>) => {
      const serie = state.settings.series?.find((s) => s.name === action.payload);
      if (state.settings.series === undefined) {
        state.settings.series = [];
      }
      if (serie === undefined) {
        state.settings.series.push({ name: action.payload, serieType: getDefaultSerieType(state.settings.type) });
      }
      const settings = getLinearSettings(state.settings);
      if (settings) {
        settings.valueAxis = updateValueAxis(settings);
      }
    },
    removeSeriesAction: (state, action: PayloadAction<string>) => {
      removeSerie(state, action.payload);
      const settings = getLinearSettings(state.settings);
      if (settings) {
        settings.valueAxis = updateValueAxis(settings);
      }
    },
    updateSeriesSettingsAction: (state, action: PayloadAction<{ name: string; changes: Partial<SeriesSettings> }>) => {
      if (state.settings.series !== undefined) {
        const series = state.settings.series;
        const serie = series.find((s) => s.name === action.payload.name);
        if (serie !== undefined) {
          const copy = { ...serie, ...action.payload.changes };
          series[series.indexOf(serie)] = copy;
        }
        const settings = getLinearSettings(state.settings);
        if (settings) {
          settings.valueAxis = updateValueAxis(settings);
        }
      }
    },
    updateSerieValueAxisSettingsAction: (
      state,
      action: PayloadAction<{ name: string; axis: Partial<ValueAxisType> }>
    ) => {
      if (state.settings.series !== undefined) {
        const series = state.settings.series;
        const serie = series.find((s) => s.name === action.payload.name) as LinearSeriesSettings;
        if (serie !== undefined) {
          serie.valueAxis = action.payload.axis;
          const settings = getLinearSettings(state.settings);
          if (settings) {
            settings.valueAxis = updateValueAxis(settings);
          }
        }
      }
    },
  },
});

export const {
  setArgumentsAction,
  addArgumentAction,
  addConditionAction,
  removeArgumentAction,
  moveArgumentAction,
  setConditionsAction,
  removeConditionAction,
  moveConditionAction,
  updateConditionAction,
  updateConditionConfigAction,
  updateArgumentAction,
  updateArgumentConfigAction,
  setMeasuresAction,
  addMeasureAction,
  removeMeasureAction,
  moveMeasureAction,
  updateMeasureAction,
  updateMeasureConfigAction,
  setSetingsAction,
  updateSettingsAction,
  updateReportTypeSettingsAction,
  updateLegendSettingsAction,
  updateAxisSettingsAction,
  updateAxisArgumentSettingsAction,
  updateAxisValueSettingsAction,
  addSeriesAction,
  removeSeriesAction,
  updateSeriesSettingsAction,
  updateSerieValueAxisSettingsAction,
} = fieldsStateChartSlice.actions;

export default fieldsStateChartSlice;
