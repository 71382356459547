import { useMemo } from "react";
import { useParams } from "react-router";
import Logout from "../../shared/auth/Logout";

const LogoutContainer = () => {
  const { client } = useParams();

  const redirectUri = useMemo(() => {
    return `${new URL(window.location.href).origin}/${client}/loggedout`;
  }, [client]);

  return <Logout redirectUri={redirectUri} />;
};

export default LogoutContainer;
