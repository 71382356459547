import { Stack } from "@mui/material";
import { useCallback } from "react";
import {
  BiAggregation,
  FieldConfiguration,
  FieldConfigurationType,
  MeasureDateRange,
  ReportField,
} from "../../../../../../shared/reporting/api/biClient.types";
import DateRanges from "../measures/DateRanges";
import AggregationType from "./AggregationType";

export interface DimensionAggregationProps {
  field: ReportField;
  onChange: (value: Partial<FieldConfiguration>) => void;
}

const DimensionAggregation = ({ field, onChange }: DimensionAggregationProps) => {
  const isRangeSelectionDisabled = !field.config.aggregation || field.config.aggregation === BiAggregation.None;

  const onChangeAggregation = useCallback(
    (value: BiAggregation | undefined) => {
      const obj: Partial<FieldConfiguration> = { aggregation: value };
      if (!field.config.dateRange) {
        obj.dateRange = MeasureDateRange.EndingBalance;
      }
      if (value === BiAggregation.None || !value) {
        obj.type = FieldConfigurationType.Dimension;
      } else {
        obj.type = FieldConfigurationType.Aggregation;
      }
      onChange(obj);
    },
    [field.config.dateRange, onChange]
  );

  const handleDateRangeChange = useCallback(
    (value: MeasureDateRange) => {
      onChange({ dateRange: value });
    },
    [onChange]
  );

  return (
    <Stack px={2} py={1.5} spacing={1.5}>
      <AggregationType field={field} onChangeAggregation={onChangeAggregation} />
      {!isRangeSelectionDisabled && (
        <DateRanges
          dateRange={field.config.dateRange || MeasureDateRange.EndingBalance}
          onChange={handleDateRangeChange}
        />
      )}
    </Stack>
  );
};

export default DimensionAggregation;
