import { DimensionField, FieldConfiguration } from "../../../../../shared/reporting/api/biClient.types";
import BlankZeroMenuItem from "../../common/measures/menuItems/BlankZeroMenuItem";
import CustomConditionsMenuItem from "../../common/measures/menuItems/CustomConditionsMenuItem";
import ReverseSignMenuItem from "../../common/measures/menuItems/ReverseSignMenuItem";

export interface Props {
  field: DimensionField;
  saveChanges: (changes: Partial<FieldConfiguration>, causeClosing: boolean) => void;
}
const AggregationOptionsComponent = ({ field, saveChanges }: Props) => (
  <>
    <CustomConditionsMenuItem field={field} saveChanges={saveChanges} />
    <BlankZeroMenuItem field={field} saveChanges={saveChanges} />
    <ReverseSignMenuItem measure={field} saveChanges={saveChanges} />
  </>
);

export default AggregationOptionsComponent;
