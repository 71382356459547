import { Grid2, Select, Typography } from "@mui/material";
import { useMemo } from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import { MenuItemStyled } from "../../../../../../shared/components/MenuItemStyled";
import { BiAggregation } from "../../../../../../shared/reporting/api/biClient.types";
import { findAggregationTypes } from "../../../utils/aggregationHelper";
import { useSupportedAggregationsContext } from "../contexts/ExportStateContext";
import { DimensionAggregationProps } from "./DimensionAggregation";

interface Props extends Pick<DimensionAggregationProps, "field"> {
  onChangeAggregation: (value: BiAggregation | undefined) => void;
}
const AggregationType = ({ field, onChangeAggregation }: Props) => {
  const { getSupportedAggregationTypes } = useSupportedAggregationsContext();
  const availableAggregations = useMemo(() => {
    const aggregations = getSupportedAggregationTypes(field);
    return findAggregationTypes(aggregations);
  }, [field, getSupportedAggregationTypes]);

  return (
    <Grid2 container sx={{ alignItems: "center" }}>
      <Typography>Aggregation</Typography>
      <HorizontalFill />
      <Select
        value={field.config.aggregation || BiAggregation.None}
        sx={{
          width: 208,
          ".MuiSelect-select": {
            py: 0.7,
          },
        }}
      >
        {availableAggregations.map((item) => (
          <MenuItemStyled
            key={item.value}
            value={item.value}
            selected={item.value === field.config.aggregation}
            onClick={() => onChangeAggregation(item.value)}
          >
            <Typography variant="caption">{item.text}</Typography>
          </MenuItemStyled>
        ))}
      </Select>
    </Grid2>
  );
};

export default AggregationType;
