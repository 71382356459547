import { Stack } from "@mui/material";
import { useCallback, useContext, useMemo } from "react";
import ScrollableFlexContainer from "../../../../../../shared/components/ScrollableFlexContainer";
import { DimensionField, FieldConfiguration, ReportField } from "../../../../../../shared/reporting/api/biClient.types";
import MeasureFieldsContainer from "../../../common/fields/format/MeasureFieldsContainer";
import { BuilderContext } from "../../types";
import TextFieldContainer from "./TextFieldsContainer";
import DimensionFormattingContainer from "../../../common/fields/format/DimensionFormattingContainer";
import { isDimensionBased, isMeasure } from "../../../utils/fieldsHelper";
import { canApplyFormat } from "../../../common/fields/format/dimensionFormattingHelper";

export default function FormatContainer() {
  const { rowsArea, columnsArea, valuesArea } = useContext(BuilderContext);

  const fields = useMemo(() => rowsArea.values.concat(columnsArea.values), [rowsArea.values, columnsArea.values]);
  const supportingFormattingDimensions = useMemo(
    () => valuesArea.values.filter(isDimensionBased).filter(canApplyFormat),
    [valuesArea.values]
  );
  const supportingFormattingMeasures = useMemo(() => valuesArea.values.filter(isMeasure), [valuesArea.values]);

  const handleUpdateConfig = useCallback(
    (field: DimensionField, changes: Partial<FieldConfiguration>) => {
      if (columnsArea.values.indexOf(field) > -1) {
        columnsArea.updateItemConfig(field, changes);
      } else if (rowsArea.values.indexOf(field) > -1) {
        rowsArea.updateItemConfig(field, changes);
      }
    },
    [rowsArea, columnsArea]
  );

  const handleDimensionChange = useCallback(
    (dimension: ReportField, changes: Partial<FieldConfiguration>) => {
      const field = valuesArea.values.find((f) => f.config.guid === dimension.config.guid);
      if (isDimensionBased(field)) {
        valuesArea.updateItemConfig(field, changes);
      }
    },
    [valuesArea]
  );

  return (
    <ScrollableFlexContainer scrollContainerSx={{ px: "1.3rem" }}>
      <Stack gap={1}>
        <TextFieldContainer fields={fields} onUpdateConfig={handleUpdateConfig} />
        <DimensionFormattingContainer
          dimensions={supportingFormattingDimensions}
          onUpdateConfig={handleDimensionChange}
        />
        <MeasureFieldsContainer measures={supportingFormattingMeasures} onChange={valuesArea.updateItemConfig} />
      </Stack>
    </ScrollableFlexContainer>
  );
}
