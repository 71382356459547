import { Checkbox, FormControlLabel, SxProps, Theme, TypographyProps } from "@mui/material";
import TypographyWithTooltip from "./TypographyWithTooltip";
import useMergedSx from "../../../shared/hooks/useMergedSx";

interface Props {
  checked: boolean;
  label: string;
  tooltipText?: string;
  typographyProps?: TypographyProps;
  formControlLabelSx?: SxProps<Theme>;
  onChange: (newValue: boolean) => void;
}

const CheckboxField = ({ checked, onChange, label, tooltipText, typographyProps, formControlLabelSx }: Props) => {
  const mergedFormControlLabelSx = useMergedSx({ m: 0 }, formControlLabelSx);

  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={(_, value) => onChange(value)} sx={{ p: 1 }} />}
      label={<TypographyWithTooltip label={label} tooltipText={tooltipText} typographyProps={typographyProps} />}
      sx={mergedFormControlLabelSx}
    />
  );
};

export default CheckboxField;
