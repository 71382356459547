import { MeasureDateRange } from "../../../../shared/reporting/api/biClient.types";

const ranges = [
  { value: MeasureDateRange.BeginningBalance, text: "Beginning Balance", short: "BB" },
  { value: MeasureDateRange.NetChange, text: "Net Change", short: "NC" },
  { value: MeasureDateRange.EndingBalance, text: "Ending Balance", short: "EB" },
  { value: MeasureDateRange.Mtd, text: "Month-to-date", short: "MTD" },
  { value: MeasureDateRange.Qtd, text: "Quarter-to-date", short: "QTD" },
  { value: MeasureDateRange.Ytd, text: "Year-to-date", short: "YTD" },
];

export function getRanges() {
  return ranges;
}

export function dateRangeToShortString(range: MeasureDateRange | undefined | null) {
  const found = ranges.find((r) => r.value === range);
  return found?.short ?? "";
}
