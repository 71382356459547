import { Typography } from "@mui/material";
import { getFieldCaption } from "../../../../../utilities/Utilities";
import { ConditionField } from "../../../Types";
import PrimaryCaption from "./PrimaryCaption";

interface Props {
  field: ConditionField;
  captionColor?: string;
  captionVariant?: "caption" | "body1";
}
export default function ConditionDimensionCaption({ field, captionColor, captionVariant }: Props) {
  const caption = getFieldCaption(field);
  const isMandatory = field.config.mandatory;

  return (
    <PrimaryCaption captionVariant={captionVariant} captionColor={captionColor} text={caption}>
      {isMandatory && (
        <Typography variant="caption" sx={{ color: (theme) => theme.palette.error.main }}>
          *
        </Typography>
      )}
    </PrimaryCaption>
  );
}
