import { Box, Typography } from "@mui/material";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import {
  AreaItemType,
  DimensionFieldType,
  FieldConfiguration,
  ItemDataType,
  MeasureField,
  ReportField,
} from "../../../../../shared/reporting/api/biClient.types.ts";
import { selectDimensions } from "../../../../store/metaDataSlice.ts";
import DimensionItem from "../../common/fields/DimensionItem.tsx";
import DropFieldContainer from "../../common/fields/DropFieldContainer.tsx";
import { ConditionFieldsArea } from "../../common/fields/FieldArea.ts";
import MeasureItem from "../../common/fields/MeasureItem.tsx";
import { FieldItemProps } from "../../common/fields/types/areaFiledItem.types.ts";
import { DraggableFieldType, DraggableMetaType, FieldWrapper } from "../../common/fields/types/dropField.types.ts";
import { createTabularField } from "../../common/utilities/createFields.ts";
import { extractGuid, extractMeta } from "../../common/utilities/dropFieldContainerHelper.ts";
import { formatMeasureCaption } from "../../pivot/table/values/helper.tsx";
import { ShowFieldOptionsSettings } from "../../Types.ts";
import { isDimensionBased, isMeasure } from "../../utils/fieldsHelper.ts";
import { formatDimensionInFieldsCaption } from "../../utils/formatDimensionFieldCaptions.tsx";
import { getDimensionFields, isMeasureConfigurationValid } from "../../utils/isConfigurationValid.ts";
import { FieldsArea } from "../contexts/FieldsStateContext.tsx";
import { canDropItem } from "../utilities/fieldsDropContainerHelper.ts";
import AggregationOptionsComponent from "./AggregationOptionsComponent.tsx";
import DimensionFieldOptions from "./DimensionFieldOptions.tsx";
import FieldOptionPopupContainer from "./FieldOptionPopupContainer.tsx";
import MeasureFieldOptions from "./MeasureFieldOptions.tsx";
import OtherOptionsComponent from "./OtherOptionsComponent.tsx";

interface Props {
  fieldsArea: FieldsArea;
  conditionsArea: ConditionFieldsArea;
}

const FieldsDropContainer = ({ fieldsArea, conditionsArea }: Props) => {
  const storedDimensions = useSelector(selectDimensions);
  const dimensionFields = useMemo(() => getDimensionFields(fieldsArea.values), [fieldsArea.values]);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [currentFieldId, setCurrentFieldId] = React.useState<string>();
  const currentField = React.useMemo(() => {
    if (!currentFieldId) {
      return undefined;
    }
    return fieldsArea.values.find((f) => f.config.guid === currentFieldId);
  }, [currentFieldId, fieldsArea.values]);

  const fieldsAreaRef = React.useRef(fieldsArea);
  fieldsAreaRef.current = fieldsArea;

  const dimensions = React.useMemo(() => {
    return storedDimensions
      .filter((d) => d.type !== ItemDataType.Numeric)
      .sort((a, b) => a.caption.localeCompare(b.caption));
  }, [storedDimensions]);

  const showFieldOptions = React.useCallback((settings: ShowFieldOptionsSettings<ReportField>) => {
    setAnchorEl(settings.ref);
    setCurrentFieldId(settings.field.config.guid);
  }, []);

  const measureCaptionColor = React.useCallback(
    (value: MeasureField) => {
      const valid = isMeasureConfigurationValid(conditionsArea.values, dimensionFields, dimensions, value);
      return valid.valid ? undefined : "error.main";
    },
    [conditionsArea.values, dimensionFields, dimensions]
  );

  const handleMeasureFormatCaption = React.useCallback(
    (measure: MeasureField) => formatMeasureCaption(measure, measureCaptionColor(measure)),
    [measureCaptionColor]
  );

  const createItem = React.useCallback(
    (props: FieldItemProps<ReportField>) => {
      if (isMeasure(props.field)) {
        const field = props.field;
        return (
          <MeasureItem
            field={field}
            fields={dimensions}
            globalConditions={conditionsArea.values}
            showConditionParameter
            formatCaption={handleMeasureFormatCaption}
            canBeRemoved
            onRemoveItem={fieldsAreaRef.current.removeItem}
            updateMeasure={fieldsAreaRef.current.updateItemConfig}
            onShowOptions={showFieldOptions}
          />
        );
      } else {
        const field = props.field;
        return (
          <DimensionItem
            field={field}
            fields={dimensions}
            globalConditions={conditionsArea.values}
            canBeRemoved
            onRemoveItem={fieldsAreaRef.current.removeItem}
            formatCaption={formatDimensionInFieldsCaption}
            updateField={fieldsAreaRef.current.updateItemConfig}
            onShowOptions={showFieldOptions}
          />
        );
      }
    },
    [dimensions, conditionsArea.values, handleMeasureFormatCaption, showFieldOptions]
  );

  const handleRemove = React.useCallback(() => {
    setAnchorEl(null);
    if (currentField) {
      fieldsAreaRef.current.removeItem(currentField);
    }
  }, [currentField]);

  const closePopup = React.useCallback(() => {
    setCurrentFieldId(undefined);
    setAnchorEl(null);
  }, []);

  const handleUpdateConfiguration = useCallback(
    (changes: Partial<FieldConfiguration>, causeClosing: boolean) => {
      if (!currentField) {
        return;
      }
      fieldsAreaRef.current.updateItemConfig(currentField, changes);
      if (causeClosing) {
        setAnchorEl(null);
      }
    },
    [currentField]
  );

  const handleDimensionFieldCustomLabelChange = useCallback(
    (name: string | undefined) => {
      if (!isDimensionBased(currentField)) {
        return;
      }
      fieldsAreaRef.current.updateItemConfig(currentField, { customLabel: name });
    },
    [currentField]
  );

  const handleCanDropItem = React.useCallback(
    (item: FieldWrapper<DraggableFieldType> | DraggableMetaType) => {
      return canDropItem(item, fieldsArea.values);
    },
    [fieldsArea.values]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: ".5rem",
      }}
    >
      <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
        Table Fields
      </Typography>
      <DropFieldContainer
        areaFieldType={AreaItemType.FIELDS}
        acceptedDropTypes={[AreaItemType.DIMENSION, AreaItemType.MEASURE, AreaItemType.CONDITIONS]}
        fields={fieldsArea.values}
        canDropItem={handleCanDropItem}
        renderListItem={createItem}
        getKeyValue={getKeyValue}
        onAddItem={fieldsArea.addItem}
        onRemoveItem={fieldsArea.removeItem}
        onMoveItem={fieldsArea.moveItem}
        createListItem={createTabularField}
      />

      <FieldOptionPopupContainer anchorEl={anchorEl} cancel={closePopup}>
        {currentField?.type === DimensionFieldType.DIMENSION && (
          <DimensionFieldOptions
            field={currentField}
            AggregationOptions={
              <AggregationOptionsComponent field={currentField} saveChanges={handleUpdateConfiguration} />
            }
            cancel={closePopup}
            onRemove={handleRemove}
            onUpdateConfiguration={handleUpdateConfiguration}
            onUpdateCustomLabel={handleDimensionFieldCustomLabelChange}
          />
        )}
        {currentField?.type === DimensionFieldType.MEASURE && (
          <MeasureFieldOptions
            measure={currentField}
            dimensionFields={dimensionFields}
            conditions={conditionsArea.values}
            otherOptions={<OtherOptionsComponent measure={currentField} saveChanges={handleUpdateConfiguration} />}
            cancel={closePopup}
            saveChanges={handleUpdateConfiguration}
            onRemove={handleRemove}
          />
        )}
      </FieldOptionPopupContainer>
    </Box>
  );
};

export default FieldsDropContainer;

function getKeyValue(field: FieldWrapper<DraggableFieldType> | DraggableMetaType) {
  const guid = extractGuid(field);
  if (guid) {
    return guid;
  }
  const meta = extractMeta(field);
  if (meta) {
    return meta.name;
  }
  return "";
}
