import { Box, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import { DimensionField, FieldConfiguration } from "../../../../../../shared/reporting/api/biClient.types";

interface Props {
  field: DimensionField;
  onUpdateConfig: (field: DimensionField, changes: Partial<FieldConfiguration>) => void;
}

const TextField = ({ field, children }: PropsWithChildren<Props>) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        borderBottom: "1px dashed #E0E0E0",
      }}
    >
      <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
        {field.config.customLabel || field.meta.caption}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: ".7rem 1rem",
          gap: ".3rem",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default TextField;
