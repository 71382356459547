import { Stack, Switch, Typography } from "@mui/material";
import React from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import { SeriesSettings } from "../../../../../../shared/reporting/api/biClient.types";
import { SettingsArea } from "../../contexts/FieldsStateContext";
import { isShowSerieLabel } from "./ShowLabelItem";

interface Props {
  area: SettingsArea;
  serie: SeriesSettings;
}

export default function ShowLabelConnectorItem({ area, serie }: Props) {
  const showConnector = React.useMemo(() => isShowSerieLabelConnector(serie.name, area), [area, serie]);
  const disabled = React.useMemo(() => !isShowSerieLabel(serie.name, area), [serie, area]);

  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography sx={{ color: `${disabled ? "text.disabled" : "text.secondary"}` }}>Show Label Connector</Typography>
      <HorizontalFill />
      <Switch
        checked={showConnector}
        disabled={disabled}
        onChange={(_, value) => area.updateSerie(serie.name, { showConnector: value })}
      />
    </Stack>
  );
}

export function isShowSerieLabelConnector(name: string | undefined, area: SettingsArea) {
  return area.settings?.series?.find((s) => s.name === name)?.showConnector !== false;
}
