import { BiAggregation, ItemDataType, ReportField } from "../../../../shared/reporting/api/biClient.types";
import { Maybe } from "../../../../shared/types";
import { defaultDateFormat, defaultNumericFormat, defaultTextFormat } from "../../../formatters/NumberFormatter";

export function getAggregationTypes() {
  const items = [
    { value: BiAggregation.None, text: "None", short: "None" },
    { value: BiAggregation.Count, text: "Count", short: "Count" },
    { value: BiAggregation.CountDistinct, text: "Count (Distinct)", short: "Count Dist" },
    { value: BiAggregation.Average, text: "Average", short: "Avg" },
    { value: BiAggregation.Min, text: "Min", short: "Min" },
    { value: BiAggregation.Max, text: "Max", short: "Max" },
    { value: BiAggregation.Sum, text: "Sum", short: "Sum" },
    { value: BiAggregation.StringAgg, text: "Concatenate Values", short: "ConcVal" },
  ];

  return items;
}

export function findAggregationTypes(types: BiAggregation[]) {
  return getAggregationTypes().filter((r) => types.includes(r.value));
}

export function getAggregationType(aggregationType: BiAggregation) {
  const found = getAggregationTypes().find((r) => r.value === aggregationType);
  return found;
}

export const getDimensionAggregationType = (field: ReportField) => {
  if (!field.config.aggregation) {
    return undefined;
  }
  if (field.config.aggregation === BiAggregation.None) {
    return undefined;
  }

  return field.config.aggregation;
};

export const getDefaultFormatting = (field: ReportField) => {
  switch (field.meta.type) {
    case ItemDataType.Date:
      return defaultDateFormat;
    case ItemDataType.General:
      return defaultTextFormat;
    case ItemDataType.Numeric:
      return defaultNumericFormat;
    default:
      return undefined;
  }
};

export const isCountRelatedAggregation = (aggregation: Maybe<BiAggregation>) => {
  return aggregation === BiAggregation.Count || aggregation === BiAggregation.CountDistinct;
};
