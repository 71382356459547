import { Grid2 } from "@mui/material";
import ScrollableFlexContainer from "../../../../../shared/components/ScrollableFlexContainer";
import GeneralSettings from "./GeneralSettings";
import GroupSettings from "./GroupSettings";
import MeasureSettings from "./MeasureSettings";

export default function SettingsContainer() {
  return (
    <ScrollableFlexContainer>
      <Grid2 container sx={{ gap: 2, flexDirection: "column", px: "1.3rem", width: "100%" }}>
        <GeneralSettings />
        <GroupSettings />
        <MeasureSettings />
      </Grid2>
    </ScrollableFlexContainer>
  );
}
