import { ListItemIcon, ListItemText, MenuItem, Typography } from "@mui/material";

interface Props {
  title: string;
  Icon: JSX.Element;
  onClick: () => void;
}

export default function ExportMenuItem({ title, onClick, Icon }: Props) {
  return (
    <MenuItem onClick={onClick}>
      <ListItemIcon>{Icon}</ListItemIcon>
      <ListItemText>
        <Typography sx={{ color: "text.primary" }}>{title}</Typography>
      </ListItemText>
    </MenuItem>
  );
}
