import {
  DimensionDescriptor,
  FieldConfiguration,
  ReportField,
} from "../../../../../shared/reporting/api/biClient.types";
import { ConditionField } from "../../Types";
import CustomConditions from "../../pivot/table/values/CustomConditions";
import AreaFieldItem from "./areaFields/AreaFieldItem";
import { FieldItemProps } from "./types/areaFiledItem.types";

interface Props<MeasureField> extends FieldItemProps<MeasureField> {
  fields: DimensionDescriptor[];
  globalConditions: ConditionField[];
  showConditionParameter?: boolean;
  updateMeasure: (field: ReportField, change: Partial<FieldConfiguration>) => void;
}

export default function MeasureItem<T extends ReportField>(props: Props<T>) {
  const {
    field,
    fields,
    globalConditions,
    canBeRemoved,
    hideOptions,
    onRemoveItem,
    formatCaption,
    onShowOptions,
    updateMeasure,
  } = props;

  return (
    <>
      <AreaFieldItem
        field={props.field}
        canBeRemoved={canBeRemoved}
        hideOptions={hideOptions}
        onRemoveItem={onRemoveItem}
        formatCaption={formatCaption}
        onShowOptions={onShowOptions}
      />
      <CustomConditions
        field={field}
        fields={fields}
        globalConditions={globalConditions}
        canBeRemoved={canBeRemoved}
        updateField={updateMeasure}
      />
    </>
  );
}
