import { ReactNode } from "react";
import {
  ConditionConfiguration,
  DimensionDescriptor,
  MetaField,
  Report,
  ReportConfiguration,
  SortConfiguration,
} from "../../../shared/reporting/api/biClient.types";
import { FromReport } from "../../../shared/reporting/components/reports/FromReport";

export const EMPTY_CROSSFILTERED_CONDITION_VALUES = null;

export type MultiCaptionField = { customLabel?: string; systemLabel?: string };

export type ConditionField = MetaField<DimensionDescriptor> & {
  config: ConditionConfiguration;
  linked?: boolean;
  hasLinks?: boolean;
  invalid?: boolean;
  crossFilter?: {
    loading?: boolean;
    toRefresh?: boolean;
    values?: unknown[] | typeof EMPTY_CROSSFILTERED_CONDITION_VALUES;
  };
  dictionary?: { loading?: boolean; loaded?: boolean; failedToLoad?: boolean };
};

export type SortField = MetaField<DimensionDescriptor> & {
  config: SortConfiguration;
};

export type ShowFieldOptionsSettings<T> = {
  field: T;
  ref: HTMLElement | null;
  showOnlySelected: boolean;
};

export interface SaveReportDialogProps {
  report: Report;
  open: boolean;
  title?: string;
  saveBtnTitle?: string;
  reportConfiguration?: ReportConfiguration;
  onClose: () => void;
}

export interface SaveAsDialogProps {
  report: FromReport;
  reportConfiguration: ReportConfiguration;
  addReport: (report: Report) => Promise<Report | undefined>;
  onChanged: (reportId: string) => void;
  onClose: () => void;
}

export interface EditorProps {
  report: Report;
  exportAllowed?: boolean;
  ReportTypeSelector: ReactNode;
}

export interface TableComponentProps {
  ReportTypeSelector: ReactNode;
}
