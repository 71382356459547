import objectHash from "object-hash";
import { useContext, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { PivotColumnResponse } from "../../../../../api/biApi.types";
import { selectRefreshToken } from "../../../../../store/currentReportSlice";
import { selectDimensions } from "../../../../../store/metaDataSlice";
import { DelayObservable } from "../../../../../utilities/Observers";
import { isPivotConfigurationValid } from "../../../utils/isConfigurationValid";
import { BuilderContext } from "../../types";
import { getAllFilters } from "../utilities/getAllConditions";
import useDataLoadingViewer from "./useDataLoadingViewer";

export default function useGridStateViewer() {
  const dimensions = useSelector(selectDimensions);

  const [columns, setColumns] = useState<PivotColumnResponse[]>([]);

  const { conditionsArea, rowsArea, columnsArea, valuesArea } = useContext(BuilderContext);
  const { calculate, error, calculating, pivot, failedMeasures } = useDataLoadingViewer();
  const refreshToken = useSelector(selectRefreshToken);

  const conditionsFiltersHash = useMemo(
    () => objectHash(getAllFilters(conditionsArea.values, valuesArea.values)),
    [conditionsArea.values, valuesArea.values]
  );

  useLayoutEffect(() => {
    const subscription = DelayObservable.subscribe(() => calculate());
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const configurationValid = useMemo(() => {
    const dimensionFields = rowsArea.values.concat(columnsArea.values).concat(rowsArea.values);
    return isPivotConfigurationValid(conditionsArea.values, dimensionFields, valuesArea.values, dimensions);
  }, [columnsArea.values, conditionsArea.values, dimensions, rowsArea.values, valuesArea.values]);

  useEffect(() => {
    if (!configurationValid.valid) return;
    DelayObservable.next(Math.random().toString());
  }, [conditionsFiltersHash, refreshToken, configurationValid.valid]);

  useEffect(() => {
    if (!pivot) {
      setColumns([]);
    } else {
      const gridColumns = pivot.columns;
      setColumns(gridColumns);
    }
  }, [pivot]);

  return { pivot, columns, error, calculating, configurationValid, failedMeasures };
}
