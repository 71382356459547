import { SxProps, Theme, Typography } from "@mui/material";
import {
  ItemDataType,
  MetaDescriptorBase,
  Report,
  ReportField,
  ReportTemplate,
} from "../../shared/reporting/api/biClient.types";
import { formatDateShort } from "../../shared/utilities/dateUtils";
import { getDisplayCaption } from "../components/builder/utils/captionsHelper";

export const formatDate = (date: Date) => formatDateShort(date);

export const parseDate = (dateStr: string) => {
  return new Date(dateStr);
};

export const isDateField = (field: MetaDescriptorBase) => {
  return field.type === ItemDataType.Date;
};

export const getFieldCaption = (
  field: { config: { customLabel?: string; systemLabel?: string }; meta: { caption: string } } | undefined
) => {
  if (field === undefined) return "No Name";
  return getDisplayCaption(field.meta.caption, field.config.customLabel, field.config.systemLabel);
};

export const styleCaption = (field: ReportField, value: string | undefined) => {
  if (!field.config.style?.fontStyle) {
    return <Typography component={"span"}>{value}</Typography>;
  }

  return (
    <Typography
      component="span"
      sx={{
        fontWeight: field.config.style.fontStyle.indexOf("bold") > -1 ? "bold" : "inherit",
        textDecoration: field.config.style.fontStyle.indexOf("underlined") > -1 ? "underline" : "inherit",
        fontStyle: field.config.style.fontStyle.indexOf("italic") > -1 ? "italic" : "inherit",
      }}
    >
      {value}
    </Typography>
  );
};

export function insertItemAt<T>(array: T[], item: T, index: number): T[] {
  if (index < 0 || index > array.length) {
    throw new Error("Index is out of range");
  }

  return [...array.slice(0, index), item, ...array.slice(index)];
}

export function isEmptyObject(obj: Record<string, unknown>): boolean {
  return Object.keys(obj).length === 0;
}

export function areAllFieldsEmpty(obj: Record<string, unknown>): boolean {
  return Object.values(obj).every((value) => {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else if (typeof value === "object" && value !== null) {
      return isEmptyObject(value as Record<string, unknown>);
    }
    return false;
  });
}

export const isNewReport = (report: Report | ReportTemplate) => {
  return report.reportId === undefined;
};

export const getBiDataGridStyles: SxProps<Theme> = (t: Theme) => ({
  "--DataGrid-rowBorderColor": t.palette.divider,
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: "divider",
  ".MuiDataGrid-virtualScroller--hasScrollX .MuiDataGrid-columnHeader--last": {
    borderRight: "1px solid var(--DataGrid-rowBorderColor)",
  },
  ".MuiDataGrid-columnHeaders": {
    ".MuiDataGrid-columnHeaderTitleContainer": {
      ".MuiDataGrid-iconButtonContainer": {
        ".MuiBadge-badge": {
          display: "none",
        },
      },
    },
    ".MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainer": {
      borderBottom: "none",
    },
    ".MuiDataGrid-columnHeader--filledGroup": {
      borderBottom: "1px solid var(--DataGrid-rowBorderColor)",
    },
    "[role=row]": {
      bgcolor: "#f5f5f5",
      ".MuiDataGrid-columnHeader": {
        bgcolor: "#f5f5f5",
      },
    },
  },
  ".MuiDataGrid-columnSeparator": {
    color: t.palette.divider,
    "&.MuiDataGrid-columnSeparator--resizing": {
      color: t.palette.divider,
    },
  },
  ".MuiDataGrid-pinnedRows--bottom": {
    ".MuiDataGrid-cell--pinnedLeft": {
      background: "inherit",
    },
  },
  ".MuiDataGrid-container--bottom [role=row]": {
    background: "#f5f5f5",
  },
  ".MuiDataGrid-scrollbarFiller.MuiDataGrid-scrollbarFiller--borderTop": {
    borderTop: "none",
  },
  ".MuiDataGrid-bottomContainer": {
    bottom: 0,
  },
});
