import {
  DimensionDescriptor,
  FieldConfiguration,
  ReportField,
} from "../../../../../shared/reporting/api/biClient.types";
import CustomConditions from "../../pivot/table/values/CustomConditions";
import { ConditionField } from "../../Types";
import { isAggregation } from "../../utils/fieldsHelper";
import AreaFieldItem from "./areaFields/AreaFieldItem";
import { FieldItemProps } from "./types/areaFiledItem.types";

interface Props<DimensionField> extends FieldItemProps<DimensionField> {
  fields: DimensionDescriptor[];
  globalConditions: ConditionField[];
  showConditionParameter?: boolean;
  updateField: (field: ReportField, change: Partial<FieldConfiguration>) => void;
}

export default function DimensionItem<T extends ReportField>(props: Props<T>) {
  const {
    field,
    fields,
    globalConditions,
    canBeRemoved,
    hideOptions,
    onRemoveItem,
    formatCaption,
    onShowOptions,
    updateField,
  } = props;

  const aggregation = isAggregation(field);

  return (
    <>
      <AreaFieldItem
        field={props.field}
        canBeRemoved={canBeRemoved}
        hideOptions={hideOptions}
        onRemoveItem={onRemoveItem}
        formatCaption={formatCaption}
        onShowOptions={onShowOptions}
      />
      {aggregation && (
        <CustomConditions
          field={field}
          fields={fields}
          globalConditions={globalConditions}
          canBeRemoved={canBeRemoved}
          updateField={updateField}
        />
      )}
    </>
  );
}
