import { Grid2, Select, Typography } from "@mui/material";
import HorizontalFill from "../../../../../shared/components/HorizontalFill";
import { MenuItemStyled } from "../../../../../shared/components/MenuItemStyled";
import { BiAggregation, DimensionField, Grouping } from "../../../../../shared/reporting/api/biClient.types";
import { defaultDateGrouping, getDateGroupingLabel } from "../../utils/captionsHelper";

const emptyGroupingValue = "day";

interface Props {
  field: DimensionField;
  supportedDateGroupings?: (Grouping | undefined)[];
  updateGrouping: (newGrouping: Grouping | undefined) => void;
}

const DateGroupingComponent = ({ field, supportedDateGroupings, updateGrouping }: Props) => {
  const isRangeSelectionDisabled = !!field.config.aggregation && field.config.aggregation !== BiAggregation.None;
  const groupings = supportedDateGroupings || defaultDateGrouping;

  return (
    <Grid2 container sx={{ alignItems: "center", px: 2, py: 1.5, width: "100%" }}>
      <Typography>Date Grouping</Typography>
      <HorizontalFill />
      <Select
        value={field.config.grouping ?? emptyGroupingValue}
        disabled={isRangeSelectionDisabled}
        sx={{ width: 208, ".MuiSelect-select": { py: 0.7 } }}
      >
        {groupings.map((item) => (
          <MenuItemStyled
            key={item ?? "none"}
            value={item ?? emptyGroupingValue}
            selected={item === (field.config.grouping ?? emptyGroupingValue)}
            onClick={() => updateGrouping(item)}
          >
            <Typography variant="caption">{getDateGroupingLabel(item)}</Typography>
          </MenuItemStyled>
        ))}
      </Select>
    </Grid2>
  );
};

export default DateGroupingComponent;
