import { AuthSettings } from "../shared/auth/authTypes";
import { importEnvVariable } from "../shared/variables";

type EnvVar = "ADMIN_PORTAL_URI" | "DEVELOPER_TOOLS";

let variables: Record<EnvVar, string> | undefined = undefined;

const importVariables = () => ({
  ADMIN_PORTAL_URI: importEnvVariable("REACT_APP_ADMIN_PORTAL_URI"),
  DEVELOPER_TOOLS: importEnvVariable("REACT_APP_DEVELOPER_TOOLS"),
});

export const getVariables = (): Record<EnvVar, string> => {
  if (variables === undefined) {
    variables = importVariables();
  }
  return variables;
};

export const importAuthSettings = (): AuthSettings => ({
  authEndpoint: importEnvVariable("REACT_APP_AD_B2C_AUTH_ENDPOINT"),
  userFlowSignIn: "",
  userFlowSignUp: "",
  userFlowPasswordReset: "",
  clientId: importEnvVariable("REACT_APP_AD_B2C_CLIENT_ID"),
  portalAccessScope: importEnvVariable("REACT_APP_PORTAL_ACCESS_SCOPE"),
  authCookieNames: "x-entrilia-ap-authorized",
  refreshCookieNames: "x-entrilia-ap-refresh-available",
});
