import { Grid2, Stack, Typography } from "@mui/material";
import { NumberFormatterOptions } from "../../../../../../shared/reporting/api/biClient.types";
import FormatSelect from "../../FormatSelect";

interface Props {
  Label: React.ReactNode;
  format: NumberFormatterOptions | undefined | null;
  onUpdateFormatter: (formatter: NumberFormatterOptions | undefined) => void;
}

const FormatSettings = ({ Label, format, onUpdateFormatter }: Props) => {
  return (
    <Stack
      useFlexGap
      sx={{
        flex: 1,
        borderBottom: "1px dashed #E0E0E0",
        gap: ".5rem",
        pb: "1rem",
      }}
    >
      {Label}
      <Grid2 container sx={{ alignItems: "center" }}>
        <Typography sx={(theme) => ({ color: theme.palette.text.secondary, width: "5rem" })}>Format</Typography>
        <FormatSelect format={format} onUpdateFormatter={onUpdateFormatter} />
      </Grid2>
    </Stack>
  );
};

export default FormatSettings;
