import { Collapse, Grid, List, ListItem, ListItemIcon, Typography } from "@mui/material";
import { PropsWithChildren, useCallback, useState } from "react";
import ArrowRightIconRounded from "@mui/icons-material/ArrowRightRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";

interface Props {
  caption: string;
  actions?: JSX.Element;
}

const GroupListItem = (props: PropsWithChildren<Props>) => {
  const { caption, actions, children } = props;

  const [hover, setHover] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const onClick = useCallback(() => setExpanded((prev) => !prev), []);
  const onHover = useCallback(() => setHover(true), []);
  const onLeave = useCallback(() => setHover(false), []);

  return (
    <>
      <ListItem
        sx={{
          p: "4px 0",
          cursor: "pointer",
          backgroundColor: expanded === true ? "rgba(0, 0, 0, 0.04)" : "inherit",
          transition: "background-color .4s ease",
          borderRadius: "2px",
          pointerEvents: "fill",
        }}
        onMouseEnter={onHover}
        onMouseOver={onHover}
        onMouseLeave={onLeave}
      >
        <Grid container alignItems="center" onClick={onClick}>
          <ListItemIcon sx={{ minWidth: "30px" }}>
            {expanded ? <ArrowDropDownRoundedIcon fontSize="medium" /> : <ArrowRightIconRounded fontSize="medium" />}
          </ListItemIcon>
          <Typography
            variant="subtitle2"
            sx={{
              flexGrow: 1,
              pointerEvents: "none",
              color: "text.primary",
            }}
          >
            {caption}
          </Typography>
        </Grid>
        {hover && actions}
      </ListItem>
      <Collapse orientation="vertical" in={expanded}>
        <List sx={{ p: "0 0 0 1rem" }}>{children}</List>
      </Collapse>
    </>
  );
};
export default GroupListItem;
