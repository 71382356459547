import { Box, List, Typography } from "@mui/material";
import { useCallback } from "react";
import { DimensionField, Ordering } from "../../../../../shared/reporting/api/biClient.types";
import { SortingFieldsArea } from "../../pivot/types";
import { SortField } from "../../Types";
import SelectSortFieldInput from "./SelectSortFieldInput";
import SortFieldItem from "./SortFieldItem";

interface Props {
  area: SortingFieldsArea;
  availableFields: DimensionField[];
}

export const SortFieldsContainer = ({ area, availableFields }: Props) => {
  const onChangeSorting = useCallback(
    (field: SortField, ordering: Ordering | undefined) => {
      area.updateItemConfig(field, { ordering });
    },
    [area]
  );

  const onRemoveItem = useCallback(
    (field: SortField) => {
      area.removeItem(field);
    },
    [area]
  );

  return (
    <Box
      sx={() => ({
        display: "flex",
        flexDirection: "column",
        flex: 1,
        gap: ".5rem",
      })}
    >
      <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
        Sort
      </Typography>
      <Box
        sx={() => ({
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#F9F9F9",
          minHeight: "90px",
          border: "1px solid #E0E0E0",
          borderRadius: "2px",
          p: ".5rem",
          gap: ".5rem",
        })}
      >
        {area.values.length > 0 && (
          <List sx={{ p: 0, display: "flex", flexDirection: "column", gap: "5px" }}>
            {area.values.map((item) => {
              return (
                <SortFieldItem
                  key={item.meta.name}
                  field={item}
                  onRemoveItem={() => onRemoveItem(item)}
                  onChangeSorting={(order) => onChangeSorting(item, order)}
                />
              );
            })}
          </List>
        )}
        <SelectSortFieldInput area={area} availableFields={availableFields} />
      </Box>
    </Box>
  );
};

export default SortFieldsContainer;
