import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { IconButton } from "@mui/material";
import { forwardRef } from "react";
import TooltipWrapper from "../../../../../../shared/components/TooltipWrapper";

interface Props {
  show: boolean;
  onClick?: () => void;
}

const FieldItemShowOnGridButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { show, onClick, ...others } = props;
  return (
    <TooltipWrapper title="Show On Grid" placement="bottom">
      <IconButton
        ref={ref}
        sx={{ height: "28px", width: "28px", visibility: show ? "visible" : "hidden" }}
        onClick={onClick}
        {...others}
      >
        <ArrowForwardRoundedIcon sx={(theme) => ({ color: theme.palette.secondary.light })} />
      </IconButton>
    </TooltipWrapper>
  );
});

FieldItemShowOnGridButton.displayName = "FieldItemShowOnGridButton";

export default FieldItemShowOnGridButton;
