import React from "react";
import { useSelector } from "react-redux";
import { ConditionConfiguration, Ordering, ReportField } from "../../../../../shared/reporting/api/biClient.types";
import { ColumnSorting, RunTabularReportRequest } from "../../../../api/biApi.types";
import { selectCurrentReport, selectSessionId } from "../../../../store/currentReportSlice";
import { selectLogQueries } from "../../../../store/devToolsSlice";
import { ConditionField } from "../../Types";
import { isDimensionBased, isMeasure } from "../../utils/fieldsHelper";
import { useFieldsStateContext } from "../contexts/FieldsStateContext";
import { getSorting } from "../utilities/gridStateHelper";

export function useRequestConfigViewer() {
  const { conditionsArea, fieldsArea, sortingArea } = useFieldsStateContext();

  const report = useSelector(selectCurrentReport);
  const sessionId = useSelector(selectSessionId);
  const isQueryLoggingActive = useSelector(selectLogQueries);

  const sorting = React.useMemo(
    () => getSorting(sortingArea.values, fieldsArea.values),
    [sortingArea.values, fieldsArea.values]
  );

  const requestConfig = React.useMemo(() => {
    const sorts = sorting
      .map((s) => {
        const field = fieldsArea.values.find((v) => v.config.guid === s.columnName);
        if (!isDimensionBased(field)) return undefined;
        return { dimensionName: field.meta.name, sortAsc: s.direction === "asc" } as ColumnSorting;
      })
      .filter((cs): cs is ColumnSorting => !!cs);

    const request: RunTabularReportRequest = {
      ...createTabularDataRequest(conditionsArea.values, fieldsArea.values, sorts),
      reportId: report?.reportId ?? "",
      sessionId,
      withDrilldown: true,
      useQueryLogging: isQueryLoggingActive === true,
    };
    return request;
  }, [sorting, conditionsArea.values, fieldsArea.values, report?.reportId, sessionId, isQueryLoggingActive]);

  return { requestConfig };
}

function createTabularDataRequest(allConditions: ConditionField[], fields: ReportField[], sorting: ColumnSorting[]) {
  const customConditions = fields
    .filter((f) => isMeasure(f) && f.config.useCustomConditions && f.config?.customConditions)
    .map((f) => f.config.customConditions)
    .flat()
    .filter((c): c is ConditionConfiguration => !!c && c.parameter === true);

  const conditions = [...allConditions.map((c) => ({ ...c.config, invalid: c.invalid })), ...customConditions].filter(
    (c) => c.parameter === true
  );

  const result = {
    conditions,
    sort: sorting.map((s) => ({
      name: s.dimensionName,
      ordering: s.sortAsc ? Ordering.Ascending : Ordering.Descending,
      caption: s.caption,
    })),
  };

  return result;
}
