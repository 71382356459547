import {
  BiAggregation,
  DimensionField,
  NumberFormatterOptions,
} from "../../../../../../shared/reporting/api/biClient.types";
import { Maybe } from "../../../../../../shared/types";
import { defaultCountAggregationFormat } from "../../../../../formatters/NumberFormatter";
import { isCountRelatedAggregation } from "../../../utils/aggregationHelper";

/**
 * Returns a default format based on whether the given aggregation is count-related or
 * uses the field's default format.
 */
export const getDefaultFormatByAggregation = (currentField: DimensionField, aggregation: Maybe<BiAggregation>) => {
  if (isCountRelatedAggregation(aggregation)) {
    return defaultCountAggregationFormat;
  }

  return currentField.meta.defaultFormat as NumberFormatterOptions;
};
