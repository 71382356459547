import { Stack } from "@mui/material";
import {
  DimensionField,
  FieldConfiguration,
  NumberFormatterOptions,
} from "../../../../../../shared/reporting/api/biClient.types";
import FormatSettings from "../../../common/fields/format/FormatSettings";
import { canApplyFormat, getFormat, getLabel } from "./dimensionFormattingHelper";
import { useCallback } from "react";
import { getDefaultFormatByAggregation } from "../utils/getNewDefaultFormatFormat";

interface Props {
  dimensions: DimensionField[];
  onUpdateConfig: (dimension: DimensionField, changes: Partial<FieldConfiguration>) => void;
}

export default function DimensionFormattingContainer({ dimensions, onUpdateConfig }: Props) {
  const handleUpdateFormatter = useCallback(
    (field: DimensionField, formatter: NumberFormatterOptions | undefined) => {
      // Default format selected
      if (formatter === undefined) {
        formatter = getDefaultFormatByAggregation(field, field.config.aggregation);
      }
      onUpdateConfig(field, { format: formatter });
    },
    [onUpdateConfig]
  );

  return (
    <Stack gap={1}>
      {dimensions.filter(canApplyFormat).map((value, i) => {
        const label = getLabel(value);
        return (
          <FormatSettings
            key={i}
            Label={label}
            format={getFormat(value)}
            onUpdateFormatter={(formatter) => handleUpdateFormatter(value, formatter)}
          />
        );
      })}
    </Stack>
  );
}
