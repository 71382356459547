import { useCallback, useEffect, useRef, useState } from "react";
import { useDragDropManager } from "react-dnd";
import { EMPTY, interval, map, takeWhile } from "rxjs";
import { ApiResponse } from "../../../../../shared/api/types";

interface Props<T extends ApiResponse<R>, R> {
  request: () => void;
  cancelRequest: () => void;
  handleBuildDone: (data: T) => void;
  handleBuildError: (reason: string | undefined) => void;
  onProgressChange: (progress: number | undefined) => void;
}

interface Trigger {
  prevRefreshToken: string;
  currentRefreshToken: string;
}

const isManuallyTriggered = (trigger: Trigger) => trigger.prevRefreshToken !== trigger.currentRefreshToken;

const DELAY_MS = 2500;
const MAX_PROGRESS_VALUE = 100;
const PROGRESS_STEP = 5;
const intervalMs = DELAY_MS / (MAX_PROGRESS_VALUE / PROGRESS_STEP);

const useDeferredBuild = <T extends ApiResponse<R>, R>({
  request,
  cancelRequest,
  handleBuildDone,
  onProgressChange,
  handleBuildError,
}: Props<T, R>) => {
  const dragDropManager = useDragDropManager();

  const [buildResult, setBuildResult] = useState<T>();
  const [isDragging, setIsDragging] = useState(false);
  const [trigger, setTrigger] = useState<Trigger>();

  const isInitialRequestRef = useRef(true);
  const memoizedActions = useRef({
    request,
    cancelRequest,
    handleBuildDone,
    onProgressChange,
    handleBuildError,
  });
  memoizedActions.current = { request, cancelRequest, handleBuildDone, onProgressChange, handleBuildError };

  const resetInitialRequestFlag = useCallback(() => (isInitialRequestRef.current = true), []);

  const doRequest = useCallback(
    (refreshToken: string) =>
      setTrigger((prev) => ({
        prevRefreshToken: prev?.currentRefreshToken || "",
        currentRefreshToken: refreshToken,
      })),
    []
  );

  const onBuildDone = useCallback((data: T) => {
    setBuildResult(data);
  }, []);

  const onBuildError = useCallback((reason: string | undefined) => {
    memoizedActions.current.handleBuildError(reason);
    memoizedActions.current.onProgressChange(undefined);
  }, []);

  const cancelCurrentAwaiter = useCallback(() => {
    setTrigger(undefined);
    memoizedActions.current.onProgressChange(undefined);
  }, []);

  useEffect(() => {
    if (buildResult === undefined) {
      return;
    }
    if (!isDragging) {
      memoizedActions.current.handleBuildDone(buildResult);
      setBuildResult(undefined);
    }
  }, [buildResult, isDragging]);

  useEffect(() => {
    if (!trigger) {
      return;
    }
    let cancelled = false;

    const shouldDelayRequest = isInitialRequestRef.current === false && !isManuallyTriggered(trigger);

    if (shouldDelayRequest) {
      memoizedActions.current.onProgressChange(undefined);
    }

    let progress = 0;
    const subscription = (
      shouldDelayRequest
        ? interval(intervalMs).pipe(
            map(() => (progress += PROGRESS_STEP)),
            takeWhile((value) => value <= MAX_PROGRESS_VALUE)
          )
        : EMPTY
    ).subscribe({
      next: (value) => memoizedActions.current.onProgressChange(value),
      complete: () => {
        if (cancelled) {
          return;
        }
        memoizedActions.current.cancelRequest();
        memoizedActions.current.request();
        isInitialRequestRef.current = false;
        setTimeout(() => {
          if (cancelled) {
            return;
          }
          memoizedActions.current.onProgressChange(undefined);
          //As far as LinearProgress has .4s animation, we need to wait for it to finish
        }, 400);
      },
      error: () => {
        if (cancelled) {
          return;
        }
        memoizedActions.current.onProgressChange(undefined);
      },
    });

    return () => {
      cancelled = true;
      subscription?.unsubscribe();
    };
  }, [trigger]);

  useEffect(() => {
    const monitor = dragDropManager.getMonitor();
    const unsubscribe = monitor.subscribeToStateChange(() => setIsDragging(monitor.isDragging()));
    return () => unsubscribe();
  }, [dragDropManager]);

  useEffect(
    () => () => {
      memoizedActions.current.onProgressChange(undefined);
    },
    []
  );

  return { doRequest, onBuildDone, onBuildError, resetInitialRequestFlag, cancelCurrentAwaiter };
};

export default useDeferredBuild;
