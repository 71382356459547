import {
  AreaItemType,
  CalculateByField,
  ConditionConfiguration,
  DimensionFieldType,
  FieldConfiguration,
  FieldConfigurationType,
  MeasureDescriptor,
  MeasureField,
  MeasureUnitTable,
  ReportField,
} from "../../../../shared/reporting/api/biClient.types";
import { checkUnitTable } from "../../../../shared/reporting/utils/checkUnitTable";
import { toAmountType, toCalculateBy } from "../common/customMeasure/utilities/measureConverter";
import { createFieldId } from "../common/utilities/createFieldId";
import { isMeasure } from "./fieldsHelper";

export class MeasureUtils {
  static createValueField(field: MeasureDescriptor, areaItemType: AreaItemType): MeasureField {
    const valueField: MeasureField = {
      type: DimensionFieldType.MEASURE,
      meta: field,
      areaItemType,
      config: {
        type: FieldConfigurationType.Measure,
        ...field.configuration,
        guid: createFieldId(field.name),
        name: field.name,
        customConditions: field.customConditions?.map(
          (cc): ConditionConfiguration => ({ filter: cc, guid: createFieldId(cc.dimensionName) })
        ),
        useCustomConditions: (field.customConditions?.length || 0) > 0,
        calculateByField: toCalculateBy(field.units),
        amountType: toAmountType(field.units),
      },
    };

    return valueField;
  }
}

export function updateCalculateByFieldForCustomMeasures<
  T extends {
    updateMeasure: (field: ReportField, changes: Partial<ReportField>) => void;
    updateMeasureConfig: (field: ReportField, changes: Partial<FieldConfiguration>) => void;
    removeMeasure: (field: ReportField) => void;
    values: ReportField[];
  },
>(fieldsState: T, measures: MeasureDescriptor[]) {
  if (fieldsState.values.length === 0) return;
  fieldsState.values.forEach((field) => {
    if (!isMeasure(field)) {
      return;
    }
    const measure = measures.find((m) => m.name === field.meta.name && m.custom);
    if (measure) {
      const calculatedBy = toCalculateBy(measure.units);
      if (field.config.calculateByField === undefined) {
        const unitsChanged = calculatedBy !== field.config.calculateByField;
        if (unitsChanged) {
          fieldsState.updateMeasureConfig(field, {
            calculateByField: calculatedBy,
          });
        }
      }
      fieldsState.updateMeasure(field, { meta: measure });
    } else {
      const measureWasRemoved = !measures.find((m) => m.name === field.meta.name);
      if (measureWasRemoved) {
        fieldsState.removeMeasure(field);
      }
    }
  });
}

export function updateMeasureFields<
  T extends {
    updateMeasureField: (field: ReportField, changes: Partial<ReportField>) => void;
    updateFieldConfig: (field: ReportField, changes: Partial<FieldConfiguration>) => void;
    removeField: (field: ReportField) => void;
    fields: ReportField[];
  },
>(fieldsState: T, measures: MeasureDescriptor[]) {
  if (fieldsState.fields.length === 0) return;
  fieldsState.fields.forEach((field) => {
    if (isMeasure(field)) {
      const measure = measures.find((m) => m.name === field.meta.name);
      if (measure !== undefined) {
        const calculatedBy = toCalculateBy(measure.units);
        if (field.config.calculateByField === undefined) {
          const unitsChanged = calculatedBy !== field.config.calculateByField;
          if (unitsChanged) {
            fieldsState.updateFieldConfig(field, { calculateByField: calculatedBy });
          }
        }
        fieldsState.updateMeasureField(field, { meta: measure });
      } else {
        const measureWasRemoved = !measures.find((m) => m.name === field.meta.name);
        if (measureWasRemoved) {
          fieldsState.removeField(field);
        }
      }
    }
  });
}

export function isGlTableMeasure(meta: Pick<MeasureDescriptor, "units">) {
  return !Object.values(meta.units).some((unit) => checkUnitTable(unit.table, MeasureUnitTable.Memo));
}

export function isSharesRelatedMeasure(measure: MeasureField) {
  return measure.config.calculateByField === CalculateByField.Shares;
}
