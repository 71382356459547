import { ReportField } from "../../../../../../shared/reporting/api/biClient.types";
import PrimaryCaption from "./PrimaryCaption";

interface Props {
  field: ReportField;
  captionColor?: string;
}
export default function MeasureDimensionCaption({ field, captionColor }: Props) {
  const caption = field.config.customLabel || field.meta.caption;

  return <PrimaryCaption captionColor={captionColor} text={caption} />;
}
