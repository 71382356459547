import { Typography } from "@mui/material";
import {
  BiAggregation,
  DimensionField,
  ItemDataType,
  FieldConfigurationType,
  FormatType,
} from "../../../../../../shared/reporting/api/biClient.types";
import { getDisplayCaption } from "../../../utils/captionsHelper";
import { getAggregationType } from "../../../utils/aggregationHelper";

const numericSupportedAggregations = [
  BiAggregation.Average,
  BiAggregation.Sum,
  BiAggregation.Count,
  BiAggregation.Max,
  BiAggregation.Min,
  BiAggregation.CountDistinct,
  BiAggregation.None,
];

const dateSupportedAggregations = [BiAggregation.Count, BiAggregation.CountDistinct];
const textSupportedAggregations = [BiAggregation.Count, BiAggregation.CountDistinct];

const supportedAggregations = {
  [ItemDataType.Numeric]: numericSupportedAggregations,
  [ItemDataType.Date]: dateSupportedAggregations,
  [ItemDataType.General]: textSupportedAggregations,
};

export const canApplyFormat = (field: DimensionField) => {
  if (field.meta.type === ItemDataType.General || field.meta.type === ItemDataType.Date) {
    if (field.config.type !== FieldConfigurationType.Aggregation) {
      return false;
    }
  }
  return supportedAggregations[field.meta.type].includes(field.config.aggregation || BiAggregation.None);
};

export const getLabel = (field: DimensionField) => {
  const caption = getDisplayCaption(field.meta.caption, field.config.customLabel);

  return (
    <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
      {caption}
      {field.config.aggregation && (
        <Typography component={"span"} sx={{ color: "text.secondary", ml: 1 }}>
          ({getAggregationType(field.config.aggregation)?.text})
        </Typography>
      )}
    </Typography>
  );
};

export const getFormat = (field: DimensionField) => {
  return (
    field.config.format ||
    (field.meta.defaultFormat?.formatType === FormatType.Numeric ? field.meta.defaultFormat : undefined)
  );
};
