import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import CloseIconButton from "../../../../../../../shared/components/CloseIconButton";
import { NumberFormatterOptions } from "../../../../../../../shared/reporting/api/biClient.types";
import { formatNumber } from "../../../../../../formatters/NumberFormatter";
import CurrencyIcon from "../../../../../../icons/CurrencyIcon";
import NumberIcon from "../../../../../../icons/NumberIcon";
import PercentageIcon from "../../../../../../icons/PercentageIcon";
import Abbreviation from "./Abbreviation";
import Decimals from "./Decimals";
import FormatTypeItem, { FormatType } from "./FormatTypeItem";

interface Props {
  formatOptions: NumberFormatterOptions | undefined;
  applyFormat: (formatOptons: NumberFormatterOptions) => void;
  close: () => void;
}

export const MeasureCustomFormatPopup = (props: Props) => {
  const { formatOptions, applyFormat, close } = props;

  const [selectedFormatType, setSelectedFormatType] = useState(() => {
    if (formatOptions?.percentage === true || formatOptions?.convertToPercentage === true) {
      return FormatType.Percentage;
    }
    return FormatType.Number;
  });

  const [sampleFormatOptions, setSampleFormatOptions] = useState({ ...formatOptions } as NumberFormatterOptions);

  const exampleFormattedValue = useMemo(
    () => formatNumber(123456789.10123, sampleFormatOptions),
    [sampleFormatOptions]
  );

  const changeFormatType = (formatType: FormatType) => {
    const copiedObject = { ...sampleFormatOptions };
    switch (formatType) {
      case FormatType.Number: {
        copiedObject.percentage = undefined;
        copiedObject.convertToPercentage = undefined;
        break;
      }
      case FormatType.Percentage: {
        copiedObject.abbreviation = undefined;
        copiedObject.percentage = true;
        break;
      }
    }
    setSampleFormatOptions(copiedObject);
    setSelectedFormatType(formatType);
  };

  return (
    <Dialog
      open={true}
      onClose={close}
      PaperProps={{
        sx: {
          borderRadius: "8px",
        },
      }}
    >
      <Grid
        container
        sx={{
          alignItems: "center",
          justifyContent: "stretch",
          p: "1rem 1rem 0rem 1.5rem",
        }}
      >
        <Typography variant="h6" sx={{ flex: 1, color: "text.primary" }}>
          Custom Format
        </Typography>
        <CloseIconButton
          onClick={close}
          iconProps={{ fontSize: "medium", sx: (theme) => ({ color: theme.palette.text.secondary }) }}
        />
      </Grid>
      <DialogContent sx={{ p: 0 }}>
        <Grid container sx={{ justifyContent: "stretch", px: "1.5rem", pb: 2, pt: 1 }}>
          <TextField
            label="Example"
            disabled
            value={exampleFormattedValue}
            sx={(theme) => ({
              flex: 1,
              backgroundColor: "#F4F5F7",
              WebkitTextFillColor: theme.palette.text.secondary,
              fieldset: {
                borderColor: "#DDDFE2 !important",
              },
            })}
          ></TextField>
        </Grid>
        <Divider />
        <Grid container>
          <Box sx={{ display: "flex", flexDirection: "column", p: "1rem .5rem" }}>
            <RadioGroup
              value={selectedFormatType || FormatType.Number}
              sx={{
                pl: "1.5rem",
                pr: ".5rem",
              }}
              onChange={(_, value) => changeFormatType(value as FormatType)}
            >
              <FormatTypeItem
                formatType={FormatType.Number}
                currentFormatType={selectedFormatType}
                icon={<NumberIcon />}
              />
              <FormatTypeItem
                formatType={FormatType.Percentage}
                currentFormatType={selectedFormatType}
                icon={<PercentageIcon />}
              />
              <FormatTypeItem
                formatType={FormatType.Currency}
                currentFormatType={selectedFormatType}
                icon={<CurrencyIcon />}
                disabled
              />
            </RadioGroup>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              p: "1.3rem",
              gap: ".5rem",
              width: "310px",
              height: "250px",
            }}
          >
            {selectedFormatType === FormatType.Number && (
              <>
                <Stack flexDirection="row" gap={2}>
                  <TextField
                    label="Prefix"
                    sx={{ pb: 1 }}
                    value={sampleFormatOptions.prefix || ""}
                    onChange={(evt) => setSampleFormatOptions({ ...sampleFormatOptions, prefix: evt.target.value })}
                  />
                  <TextField
                    label="Suffix"
                    sx={{ pb: 1 }}
                    value={sampleFormatOptions.suffix || ""}
                    onChange={(evt) => setSampleFormatOptions({ ...sampleFormatOptions, suffix: evt.target.value })}
                  />
                </Stack>
                <Abbreviation
                  formatOptions={sampleFormatOptions}
                  applyFormat={(options) => setSampleFormatOptions({ ...sampleFormatOptions, ...options })}
                />
                <Box sx={{ display: "flex", pt: ".5rem", gap: ".5rem" }}>
                  <Decimals
                    formatOptions={sampleFormatOptions}
                    applyFormat={(options) => setSampleFormatOptions({ ...sampleFormatOptions, ...options })}
                  />
                </Box>
              </>
            )}
            {selectedFormatType === FormatType.Percentage && (
              <>
                <FormControlLabel
                  sx={{ mt: "-5px" }}
                  control={<Checkbox checked={sampleFormatOptions.convertToPercentage === true} />}
                  label={<Typography>Convert to Percentage</Typography>}
                  onChange={(_, value) =>
                    setSampleFormatOptions({ ...sampleFormatOptions, convertToPercentage: value })
                  }
                />
                <Box
                  sx={{
                    display: "flex",
                    pt: ".5rem",
                    gap: ".5rem",
                  }}
                >
                  <Decimals
                    formatOptions={sampleFormatOptions}
                    applyFormat={(options) => setSampleFormatOptions({ ...sampleFormatOptions, ...options })}
                  />
                </Box>
              </>
            )}
          </Box>
        </Grid>
        <Divider />
      </DialogContent>
      <DialogActions sx={{ px: "1.5rem", py: 2 }}>
        <Button variant="text" color="secondary" onClick={close}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            applyFormat(sampleFormatOptions);
            close();
          }}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MeasureCustomFormatPopup;
