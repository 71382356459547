import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { ListItemIcon, SxProps, Theme, Typography } from "@mui/material";
import { useCallback } from "react";
import {
  DimensionDescriptor,
  MeasureDescriptor,
  MetaDescriptorBase,
} from "../../../../../../shared/reporting/api/biClient.types";
import { makeLighterBackgroundFromColor } from "../../../../../../shared/utilities/colorHelper";
import { getIcon } from "../FieldItem.helper";

interface Props<T extends MetaDescriptorBase> {
  field: T;
  sx?: SxProps<Theme>;
  caption?: string;
}

export const DimensionFieldItem = <T extends MetaDescriptorBase>({ field, caption }: Props<T>) => {
  const getColor = useCallback(
    (theme: Theme) => {
      const measure = field as unknown as MeasureDescriptor;
      return measure.custom === true ? theme.palette.info.main : theme.palette.secondary.light;
    },
    [field]
  );

  return (
    <>
      <ListItemIcon sx={(theme) => ({ minWidth: "20px", justifyContent: "center", color: getColor(theme) })}>
        {getIcon(field)}
      </ListItemIcon>
      <Typography
        variant="caption"
        sx={{
          flexGrow: 1,
          pointerEvents: "none",
          pl: 1,
          color: "text.primary",
        }}
      >
        {caption || field.caption}
      </Typography>
      {(field as unknown as DimensionDescriptor).isNew && (
        <Typography
          variant="caption"
          sx={(theme) => ({
            color: theme.palette.info.main,
            fontWeight: 500,
            fontSize: "12px",
            borderRadius: "64px",
            backgroundColor: makeLighterBackgroundFromColor(theme.palette.info.main),
            py: 0.5,
            px: 1,
            my: -0.5,
          })}
        >
          New
        </Typography>
      )}
      <ListItemIcon sx={{ minWidth: "20px" }}>
        <DragIndicatorIcon color="disabled" />
      </ListItemIcon>
    </>
  );
};

export default DimensionFieldItem;
