import { PropsWithChildren } from "react";
import FieldPopover from "../../common/fields/FieldPopover";

interface Props {
  anchorEl: HTMLElement | null;
  cancel: () => void;
}

const FieldOptionPopupContainer = ({ anchorEl, children, cancel }: PropsWithChildren<Props>) => {
  if (anchorEl === null) return null;
  return (
    <FieldPopover open={true} anchorEl={anchorEl} onClose={cancel} sx={{ width: 400, p: 0 }}>
      {children}
    </FieldPopover>
  );
};
export default FieldOptionPopupContainer;
