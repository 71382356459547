import { Stack, Switch, Typography } from "@mui/material";
import React from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import { ValueAxis } from "../../../../../../shared/reporting/api/biClient.types";
import { SettingsArea } from "../../contexts/FieldsStateContext";
import { getLinearSettings } from "../../contexts/FieldsStateContext.types";

interface Props {
  axis: ValueAxis;
  area: SettingsArea;
}

export default function ShowLabelItem({ axis, area }: Props) {
  const showLabel = React.useMemo(() => isShowValueAxisLabel(area, axis.name), [area, axis]);

  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography sx={{ color: "text.secondary" }}>Show Label</Typography>
      <HorizontalFill />
      <Switch checked={showLabel} onChange={(_, value) => area.updateValueAxis(axis.name, { showLabel: value })} />
    </Stack>
  );
}

export function isShowValueAxisLabel(area: SettingsArea, name: string) {
  return getLinearSettings(area.settings)?.valueAxis?.find((va) => va.name === name)?.showLabel !== false;
}
