import { Typography } from "@mui/material";
import { useLocalization } from "../../../hooks/useLocalization";

const VisualizationLabel = () => {
  const { common: locale } = useLocalization();
  return (
    <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
      {locale.visualizations_label}
    </Typography>
  );
};

export default VisualizationLabel;
