import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Dialog, Grid, Typography } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIconButton from "../../../../../shared/components/CloseIconButton";
import HorizontalFill from "../../../../../shared/components/HorizontalFill";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { MeasureDescriptor } from "../../../../../shared/reporting/api/biClient.types";
import { formatString } from "../../../../../shared/utilities/stringFormatter";
import biClient from "../../../../api/biApi";
import { useLocalization } from "../../../../hooks/useLocalization";
import DeleteMeasureIcon from "../../../../icons/DeleteMeasureIcon";
import { metaDataActions, selectMeasures } from "../../../../store/metaDataSlice";

interface Props {
  measure: MeasureDescriptor;
  onClose: () => void;
}

export const DeleteMeasureDialog = ({ measure, onClose }: Props) => {
  const dispatch = useDispatch();
  const measures = useSelector(selectMeasures);
  const { custom_measure: locale } = useLocalization();
  const { sendNotification } = useNotificationContext();

  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string>();

  const inUsedMeasure = useMemo(() => {
    return measures.find((m) => {
      for (const variable in m.units) {
        const unit = m.units[variable];
        if (unit === undefined) return false;
        if (unit.measureBasedUnit === true && unit.measureName === measure.name) return true;
      }
      return false;
    });
  }, [measure, measures]);

  const onDeleteMeasure = useCallback(() => {
    setSaving(true);
    biClient
      .deleteCustomMeasure(measure.name)
      .then(() => {
        dispatch(metaDataActions.deleteMeasure(measure));
        sendNotification(formatString(locale.measure_deleted, measure.caption));
        onClose();
      })
      .catch(() => {
        setError(locale.delete_error);
        setSaving(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [measure.name, locale.delete_error, onClose]);

  return (
    <Dialog open={true} PaperProps={{ sx: { width: "514px", borderRadius: 0.5, py: 2, px: 3 } }}>
      <Grid container sx={{ alignItems: "center" }}>
        <Typography variant="h6" sx={{ color: "text.primary" }}>
          Delete Measure
        </Typography>
        <HorizontalFill />
        <CloseIconButton onClick={onClose} disabled={saving} iconProps={{ fontSize: "medium", color: "secondary" }} />
      </Grid>
      {error && <Alert severity="error">{error}</Alert>}
      {inUsedMeasure && <Alert severity="warning">{formatString(locale.measure_is_used, inUsedMeasure.caption)}</Alert>}
      <Grid container sx={{ flexDirection: "column", py: 3, gap: 2, alignItems: "center" }}>
        <Box>
          <DeleteMeasureIcon />
        </Box>
        <Typography variant="h6" sx={{ color: "text.primary" }}>
          Delete {measure.caption}
        </Typography>
        <Typography sx={{ color: "text.secondary" }}>{locale.delete_measure_text}</Typography>
      </Grid>
      <Grid container sx={{ gap: 1, justifyContent: "end" }}>
        <Button variant="text" color="secondary" disabled={saving} onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          variant="outlined"
          color="error"
          disabled={saving || inUsedMeasure !== undefined}
          loading={saving}
          onClick={onDeleteMeasure}
        >
          Delete
        </LoadingButton>
      </Grid>
    </Dialog>
  );
};

export default DeleteMeasureDialog;
