import { Grid, useTheme } from "@mui/material";
import { useEffect, useMemo, useRef } from "react";
import { ChartOfAccountsItem } from "../../../../../api/biApi.types";
import { CustomMeasureReturnType, findNodeByTokenData } from "../hooks/useCustomMeasure";
import { FormulaNode } from "../utilities/fillNodes";
import PropertyItemContainer from "./PropertyItemContainer";
import { PropertyName } from "./PropertyName";
import VariablePropertyItem from "./VariablePropertyItem";

interface Props {
  formulaNode: FormulaNode;
  state: CustomMeasureReturnType;
  editable: boolean;
  chartOfAccountItems?: ChartOfAccountsItem[];
}

export const FunctionPropertyItem = ({ formulaNode, state, editable, chartOfAccountItems }: Props) => {
  const { palette } = useTheme();
  const formulaContainerRef = useRef<HTMLDivElement>(null);

  const isSelectedParameter = useMemo(() => {
    if (state.selectedFormulaParameter === undefined) return false;

    return formulaNode.linkedNodes.some((ln) => findNodeByTokenData(ln, state.selectedFormulaParameter));
  }, [state.selectedFormulaParameter, formulaNode]);

  useEffect(() => {
    if (isSelectedParameter) {
      formulaContainerRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [isSelectedParameter]);

  return (
    <PropertyItemContainer
      sx={{
        bgcolor: palette.background.grey,
        pb: 1,
        ...(formulaNode.highlighted === true ? { border: `1px solid ${palette.primary.main}` } : {}),
      }}
      ref={formulaContainerRef}
    >
      <PropertyName
        sx={{
          px: 1.5,
          height: "28px",
          bgcolor: "white",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          borderBottom: "1px solid " + palette.divider,
        }}
        text={formulaNode.text}
      />
      <Grid container component="ul" className="formulaTree" sx={{ flexDirection: "column", flex: 1 }}>
        {formulaNode.args?.map((arg, index) => {
          return (
            <VariablePropertyItem
              key={`${arg.key}_${index}`}
              chartOfAccountItems={chartOfAccountItems}
              editable={editable}
              formulaNode={arg}
              state={state}
              sx={{ mt: 1.5, flex: 1, width: "auto" }}
            />
          );
        })}
      </Grid>
    </PropertyItemContainer>
  );
};

export default FunctionPropertyItem;
