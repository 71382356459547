import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import { IconButton, ListItem, ListItemButton, Menu, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { MenuItemStyled } from "../../../../../shared/components/MenuItemStyled";
import { DimensionField } from "../../../../../shared/reporting/api/biClient.types";

interface Props {
  onAddField: (field: DimensionField) => void;
  availableFields: DimensionField[];
}

const SelectFieldInput = ({ onAddField, availableFields }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);

  const onBeforeClose = useCallback(() => {
    setOpen(false);
  }, []);

  const addFieldToGroup = useCallback(
    (field: DimensionField) => {
      onAddField(field);
      onBeforeClose();
    },
    [onAddField, onBeforeClose]
  );

  return (
    <>
      <ListItem
        role="Handle"
        ref={(ref) => setAnchorEl(ref)}
        sx={() => ({
          display: "flex",
          border: "1px solid rgba(33, 33, 33, 0.35)",
          borderRadius: "2px",
          p: "5px",
          backgroundColor: "#fff",
          height: "32px",
        })}
      >
        <ListItemButton
          disabled={availableFields.length === 0}
          onClick={() => {
            if (availableFields.length > 0) {
              setOpen(true);
            }
          }}
          sx={{ "&:hover": { bgcolor: "transparent" }, p: 0 }}
          disableTouchRipple
          disableRipple
        >
          <Typography variant="caption" sx={{ flexGrow: 1, color: "text.primary" }}>
            Select Field
          </Typography>
          <IconButton>
            <ArrowDropDownRoundedIcon fontSize="medium" />
          </IconButton>
        </ListItemButton>
      </ListItem>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={onBeforeClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)",
              width: "322px",
              maxWidth: "400px",
              maxHeight: "350px",
            },
          },
        }}
      >
        {availableFields.map((field) => {
          return (
            <MenuItemStyled key={field.config.guid} onClick={() => addFieldToGroup(field)}>
              <Typography variant="caption" noWrap>
                {field.config.customLabel || field.meta.caption}
              </Typography>
            </MenuItemStyled>
          );
        })}
      </Menu>
    </>
  );
};

export default SelectFieldInput;
