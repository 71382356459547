import { useSelector } from "react-redux";
import { Outlet } from "react-router";
import GlobalNotificationErrorSnackbar from "../../../../shared/components/GlobalNotificationErrorSnackbar";
import GlobalNotificationSnackbar from "../../../../shared/components/GlobalNotificationSnackbar";
import { NotificationContextProvider } from "../../../../shared/contexts/NotificationContext";
import sharedBiClient from "../../../../shared/reporting/api/biClient";
import { BiApiClientProvider } from "../../../../shared/reporting/contexts/BiApiClientProviderContext";
import biClient from "../../../api/biApi";
import { BiApiClient } from "../../../contexts/ApiClientProviderContext";
import { useLocalization } from "../../../hooks/useLocalization";
import { selectMetaData } from "../../../store/metaDataSlice";
import ImportantDataLoadingFailed from "../../common/ImportantDataLoadingFailed";
import { getApiReportsUrl, getEditApiReportUrl, getViewApiReportUrl } from "../common/utilities/editReportUrl";

const ReportsContainer = () => {
  const { common: locale } = useLocalization();
  const { loadingFailed } = useSelector(selectMetaData);

  if (loadingFailed) {
    return (
      <ImportantDataLoadingFailed
        title={locale.report_data_loading_failed_title}
        disclaimer={locale.report_data_loading_failed_text}
      />
    );
  }

  return (
    <BiApiClientProvider<BiApiClient>
      api={{
        ...biClient.apiReports,
        ...sharedBiClient.apiReports,
        getEditReportUrl: getEditApiReportUrl,
        getViewReportUrl: getViewApiReportUrl,
        getReportsUrl: getApiReportsUrl,
      }}
    >
      <NotificationContextProvider>
        <Outlet />
        <GlobalNotificationSnackbar />
        <GlobalNotificationErrorSnackbar />
      </NotificationContextProvider>
    </BiApiClientProvider>
  );
};

export default ReportsContainer;
