import { memo } from "react";
import { MetaDescriptorBase } from "../../../../../shared/reporting/api/biClient.types";
import DimensionFieldItem from "../fields/dimensions/DimensionFieldItem";
import FieldItemContainer from "../fields/FieldItem";

export interface DraggableProps<T extends MetaDescriptorBase> {
  field: T;
  caption?: string;
}

const FieldItemDraggable = memo(<T extends MetaDescriptorBase>(props: DraggableProps<T>) => {
  const { field, caption } = props;

  return (
    <FieldItemContainer
      field={field}
      sx={{
        p: "4px 8px",
        border: "1px solid",
        borderColor: "primary.main",
        borderRadius: "2px",
        bgcolor: "#FFF",
        boxShadow: "0px 3px 2px -1px rgba(0, 0, 0, 0.1)",
        width: "302px",
        zIndex: 3,
      }}
    >
      <DimensionFieldItem field={field} caption={caption} />
    </FieldItemContainer>
  );
});

FieldItemDraggable.displayName = "FieldItemDraggable";

export default FieldItemDraggable;
