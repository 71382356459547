import { FieldConfiguration, ReportField } from "../../../../../../shared/reporting/api/biClient.types";
import { isNumericField } from "../../../utils/isNumericField";
import SwitchMenuItem from "./SwitchMenuItem";

interface Props {
  field: ReportField;
  saveChanges: (changes: Partial<FieldConfiguration>, causeClosing: boolean) => void;
}

export default function BlankZeroMenuItem(props: Props) {
  const { field, saveChanges } = props;

  if (!isNumericField(field)) return <></>;

  return (
    <SwitchMenuItem
      caption="Blank Zero"
      value={!!field.config.blankZero}
      onClick={() => saveChanges({ blankZero: !field.config.blankZero }, false)}
    />
  );
}
