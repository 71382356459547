import { ApiResponse } from "../../shared/api/types";
import { Report, ReportAuthorizationInfo } from "../../shared/reporting/api/biClient.types";

export const manageReportSwitching = (
  isSwitchingReport: boolean,
  setSwitchingReport: (v: boolean) => void,
  getReport: () => Promise<void>
) => {
  if (isSwitchingReport) {
    setSwitchingReport(true);
  }
  getReport().finally(() => {
    setSwitchingReport(false);
  });
};

export const getExistingReportWithAuth = async (
  getReport: () => Promise<ApiResponse<Report>>,
  getReportAuthorizationInfo: () => Promise<ApiResponse<ReportAuthorizationInfo>>,
  onReportNotFound: () => void,
  onReportFound: (report: Report) => void,
  onSuccessfulAuthorization: (authorizationInfo: ReportAuthorizationInfo) => void
) => {
  try {
    const [reportResp, authorizationResp] = await Promise.all([getReport(), getReportAuthorizationInfo()]);
    if (reportResp.success && reportResp.data !== undefined) {
      onReportFound(reportResp.data);
    } else {
      onReportNotFound();
    }

    if (authorizationResp.success && authorizationResp.data !== undefined) {
      onSuccessfulAuthorization(authorizationResp.data);
    }
  } catch {
    onReportNotFound();
  }
};
