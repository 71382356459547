import { ReportField } from "../../../../../../shared/reporting/api/biClient.types";
import FieldCaptionWrapper from "../../../common/fields/field-captions/FieldCaptionWrapper";
import MeasureDimensionCaption from "../../../common/fields/field-captions/MeasureDimensionCaption";
import { dateRangeToShortString } from "../../../utils/dateRangeToString";
import { getCaptionByDateRangeType } from "../../preview/Helper";
import FieldCaptionIconsSet from "./FieldCaptionIconsSet";

export function formatMeasureCaption(measure: ReportField | undefined, captionColor?: string) {
  if (measure === undefined) return <></>;
  const customCaption = dateRangeToShortString(measure.config.dateRange);

  return (
    <FieldCaptionWrapper
      Caption={<MeasureDimensionCaption field={measure} captionColor={captionColor} />}
      subCaption={customCaption}
    >
      <FieldCaptionIconsSet config={measure.config} />
    </FieldCaptionWrapper>
  );
}

export const getMeasureCaption = (measure: ReportField | undefined) => {
  if (measure === undefined) return "";
  return measure?.config.customLabel || getCaptionByDateRangeType(measure.meta.caption, measure.config.dateRange);
};
