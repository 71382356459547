import { useCallback } from "react";
import { FieldConfiguration, ReportField } from "../../../../../../shared/reporting/api/biClient.types";
import { isMeasure } from "../../../utils/fieldsHelper";
import SwitchMenuItem from "./SwitchMenuItem";

interface Props {
  field: ReportField;
  saveChanges: (changes: Partial<FieldConfiguration>, causeClosing: boolean) => void;
}

export default function CustomConditionsMenuItem({ field, saveChanges }: Props) {
  const handleChangeState = useCallback(
    (value: boolean) => {
      if (value) {
        saveChanges({ useCustomConditions: true }, false);
      } else {
        saveChanges({ useCustomConditions: false, linkedConditions: undefined, customConditions: undefined }, false);
      }
    },
    [saveChanges]
  );

  if (isMeasure(field) && !field.meta.capabilities.customConditionsAllowed) {
    return <></>;
  }

  return (
    <SwitchMenuItem
      caption="Custom Conditions"
      value={!!field.config.useCustomConditions}
      onClick={() => handleChangeState(!field.config.useCustomConditions)}
    />
  );
}
