import { Box, Stack, Switch, Typography } from "@mui/material";
import React from "react";
import HorizontalFill from "../../../../shared/components/HorizontalFill";
import { FieldConfiguration, ReportField } from "../../../../shared/reporting/api/biClient.types";
import Conditions from "../pivot/table/conditions/Conditions";
import MeasureConditions from "../pivot/table/values/MeasureConditions";
import { ConditionFieldsArea } from "./fields/FieldArea";

interface Props {
  conditionsArea: ConditionFieldsArea;
  measuresWithConditions: ReportField[];
  updateMeasure: (field: ReportField, change: Partial<FieldConfiguration>) => void;
}

export default function ViewerParameters({ conditionsArea, measuresWithConditions, updateMeasure }: Props) {
  const [detailedView, setDetailedView] = React.useState(false);

  const fields = React.useMemo(
    () => conditionsArea.values.filter((v) => v.config.parameter === true),
    [conditionsArea.values]
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        px: "1.4rem",
        py: "1rem",
        gap: 1,
        width: "350px",
      }}
    >
      <Stack direction={"row"} alignItems="center" gap={1} pb={1}>
        <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
          Parameters
        </Typography>
        <HorizontalFill />
        <Typography>Detailed view</Typography>
        <Switch checked={detailedView} onChange={(_, value) => setDetailedView(value)} />
      </Stack>
      <Conditions
        fields={fields}
        canBeRemoved={false}
        detailed={detailedView}
        onFieldChanged={conditionsArea.updateItem}
        disableEqualitySelection={true}
      />
      <MeasureConditions
        measures={measuresWithConditions}
        globalConditions={conditionsArea.values}
        updateMeasure={updateMeasure}
      />
    </Box>
  );
}
