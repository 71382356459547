import { Box, Grid } from "@mui/material";
import React from "react";
import { useLocalization } from "../../../../../hooks/useLocalization";
import SubCaption from "./SubCaption";

interface Props {
  Caption: JSX.Element;
  subCaption: string;
  subCaptionColor?: string;
  isMandatory?: boolean;
}

export default function FieldCaptionWrapper({
  Caption,
  subCaption,
  subCaptionColor,
  children,
  isMandatory,
}: React.PropsWithChildren<Props>) {
  const { report: locale } = useLocalization();
  return (
    <Grid
      container
      flex={1}
      minWidth={0}
      display={"grid"}
      flexWrap={"nowrap"}
      gap={0.5}
      px={0.8}
      gridTemplateColumns={"minmax(0%, auto) minmax(0%, auto)"}
    >
      <Box display={"flex"} alignItems={"center"} minWidth={"0px"} overflow={"hidden"} gap={0.4}>
        {Caption}
        {children}
      </Box>

      <Box display={"flex"} alignItems={"center"} minWidth={"0px"} overflow={"hidden"} justifyContent={"end"}>
        {isMandatory && subCaption.length === 0 && (
          <SubCaption caption={locale.mandatory_filed_is_not_set} color="error.main" />
        )}
        {subCaption.length > 0 && <SubCaption caption={subCaption} color={subCaptionColor} />}
      </Box>
    </Grid>
  );
}
