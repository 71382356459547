import { Box, Typography } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  AreaItemType,
  DimensionDescriptor,
  DimensionField,
  FieldConfiguration,
  ItemDataType,
  MeasureDescriptor,
  ReportField,
} from "../../../../../../shared/reporting/api/biClient.types";
import DropFieldContainer from "../../../common/fields/DropFieldContainer";
import { DraggableFieldType, DraggableMetaType, FieldWrapper } from "../../../common/fields/types/dropField.types";
import { createRowField } from "../../../common/utilities/createFields";
import { extractMeta, isValueField } from "../../../common/utilities/dropFieldContainerHelper";
import { ShowFieldOptionsSettings } from "../../../Types";
import { isAggregation } from "../../../utils/fieldsHelper";
import { formatDimensionFieldCaption } from "../../../utils/formatDimensionFieldCaptions";
import { BuilderContext } from "../../types";
import RowFieldOptionPopup from "./RowFieldOptionPopup";

export const MaxRowsNumber = 5;

export default function RowsDropFieldsContainer() {
  const { columnsArea, rowsArea } = React.useContext(BuilderContext);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [field, setField] = useState<DimensionField>();

  const columnsRef = useRef<DimensionField[]>([]);
  columnsRef.current = columnsArea.values;

  const rowsRef = useRef<DimensionField[]>([]);
  rowsRef.current = rowsArea.values;

  useEffect(() => {
    const row = rowsArea.values.find((v) => v.meta.name === field?.meta.name);
    setField(row);
  }, [rowsArea.values, field]);

  const showOptions = useCallback((settings: ShowFieldOptionsSettings<DimensionField>) => {
    setField(settings.field);
    setAnchorEl(settings.ref);
  }, []);

  const isDimensionAlreadyAdded = useCallback((meta: DimensionDescriptor | MeasureDescriptor) => {
    return (
      rowsRef.current.some((v) => v.meta.name === meta.name) ||
      columnsRef.current.some((v) => v.meta.name === meta.name)
    );
  }, []);

  const canDropRowItem = useCallback(
    (item: FieldWrapper<ReportField> | DraggableMetaType) => {
      const meta = extractMeta(item);
      if (!meta) return false;

      if (rowsRef.current.length >= MaxRowsNumber) {
        return false;
      }
      if (item.type === AreaItemType.COLUMNS && !rowsRef.current.some((v) => v.meta.name === meta.name)) {
        return true;
      }

      if (item.type === AreaItemType.VALUES) {
        const isTheFieldIsAggregation = isValueField(item) && isAggregation(item.field);
        if (isTheFieldIsAggregation && !isDimensionAlreadyAdded(meta)) {
          return true;
        }
        return false;
      }

      if (meta.type === ItemDataType.Date) {
        const anyDateFieldAdded =
          !!columnsRef.current?.find((f) => f.meta.type === ItemDataType.Date) ||
          !!rowsRef.current?.find((f) => f.meta.type === ItemDataType.Date);

        return anyDateFieldAdded === false;
      }

      return !isDimensionAlreadyAdded(meta);
    },
    [isDimensionAlreadyAdded]
  );

  const saveChanges = useCallback(
    (changes: Partial<FieldConfiguration>) => {
      if (field === undefined) return;
      rowsArea.updateItemConfig(field, changes);
    },
    [rowsArea, field]
  );

  const handleSaveChanges = useCallback(
    (changes: Partial<FieldConfiguration>, causeClosing: boolean) => {
      saveChanges(changes);
      if (causeClosing) {
        setAnchorEl(null);
      }
    },
    [saveChanges]
  );

  const handleOnRemove = useCallback(() => {
    setAnchorEl(null);
    if (field !== undefined) {
      rowsArea.removeItem(field);
    }
  }, [rowsArea, field]);

  const handleOnCancel = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleRowFieldCustomLabelChange = (name: string | undefined) => {
    if (field) {
      rowsArea.updateItemConfig(field, { customLabel: name });
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: ".5rem" }}>
      <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
        Rows
      </Typography>
      <DropFieldContainer<DimensionField, DimensionDescriptor>
        areaFieldType={AreaItemType.ROWS}
        acceptedDropTypes={[AreaItemType.DIMENSION, AreaItemType.COLUMNS, AreaItemType.CONDITIONS, AreaItemType.VALUES]}
        fields={rowsArea.values}
        getKeyValue={getKeyValue}
        formatCaption={formatDimensionFieldCaption}
        canDropItem={canDropRowItem}
        onAddItem={rowsArea.addItem}
        onRemoveItem={rowsArea.removeItem}
        onMoveItem={rowsArea.moveItem}
        showOptions={showOptions}
        createListItem={createRowField}
      />
      {anchorEl && field && (
        <RowFieldOptionPopup
          anchorEl={anchorEl}
          field={field}
          onUpdateConfiguration={handleSaveChanges}
          cancel={handleOnCancel}
          onRemove={handleOnRemove}
          onUpdateCustomLabel={handleRowFieldCustomLabelChange}
        />
      )}
    </Box>
  );
}

function getKeyValue(field: FieldWrapper<DraggableFieldType> | DraggableMetaType) {
  return extractMeta(field)?.name ?? "";
}
