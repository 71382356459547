import { Typography } from "@mui/material";
import React from "react";
import { ItemDataType, MeasureUsage } from "../../../../../shared/reporting/api/biClient.types";
import SwitchField from "../../../common/SwitchField";
import { getMeasureCaption } from "../../pivot/table/values/helper";
import { isAggregation, isMeasure } from "../../utils/fieldsHelper";
import { isNumericField } from "../../utils/isNumericField";
import { useFieldsStateContext } from "../contexts/FieldsStateContext";
import ExpandeGroup from "./ExpandeGroup";
import StyleItem from "./StyleItem";

export default function MeasureSettings() {
  const { fieldsArea } = useFieldsStateContext();

  const numericMeasures = React.useMemo(() => {
    return fieldsArea.values.filter((f) => {
      if (isMeasure(f)) {
        return f.meta.type === ItemDataType.Numeric && f.meta.usage !== MeasureUsage.TabularOnly;
      } else if (isAggregation(f)) {
        return isNumericField(f);
      }
      return false;
    });
  }, [fieldsArea.values]);

  if (numericMeasures.length === 0) {
    return <></>;
  }

  return (
    <ExpandeGroup title="Measure Columns">
      {numericMeasures.map((field, index) => (
        <StyleItem
          key={field.config.guid}
          showBorder={numericMeasures.length > 1 && index < numericMeasures.length - 1}
        >
          <Typography variant="subtitle2" fontWeight={500} sx={{ color: "text.primary" }} pb={1}>
            {getMeasureCaption(field)}
          </Typography>
          <SwitchField
            label="Calculate Subtotals"
            checked={!field.config.hideAggregation}
            onChange={(hideAggregation) => fieldsArea.updateItemConfig(field, { hideAggregation: !hideAggregation })}
            typographyProps={{ sx: { color: "text.secondary" } }}
          />
        </StyleItem>
      ))}
    </ExpandeGroup>
  );
}
