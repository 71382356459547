import { IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import {
  ChartStyleSettings,
  LinearSeriesSettings,
  SerieType,
  SeriesSettings,
} from "../../../../../../shared/reporting/api/biClient.types";
import AreaChartIcon from "../../../../../../shared/reporting/icons/reportTypes/AreaChartIcon";
import BarChartIcon from "../../../../../../shared/reporting/icons/reportTypes/BarChartIcon";
import LineChartIcon from "../../../../../../shared/reporting/icons/reportTypes/LineChartIcon";
import { SettingsArea } from "../../contexts/FieldsStateContext";
import { getDefaultSerieType, isLinearSettings } from "../../contexts/FieldsStateContext.types";

interface Props {
  area: SettingsArea;
  serie: SeriesSettings;
}

export default function SerieTypeItem({ area, serie }: Props) {
  const supported = React.useMemo(() => isLinearSettings(area.settings), [area.settings]);
  const serieType = React.useMemo(() => getSerieType(area.settings, serie), [area, serie]);

  const hanldeSerieTypeChanged = React.useCallback(
    (value: SerieType) => area.updateSerie<LinearSeriesSettings>(serie.name, { serieType: value }),
    [area, serie]
  );

  if (!supported) {
    return <></>;
  }

  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography sx={{ color: "text.secondary" }}>Serie Type</Typography>
      <HorizontalFill />
      <BarSerie selectedType={serieType} onSelected={hanldeSerieTypeChanged} />
      <LineSerie selectedType={serieType} onSelected={hanldeSerieTypeChanged} />
      <AreaSerie selectedType={serieType} onSelected={hanldeSerieTypeChanged} />
    </Stack>
  );
}

export function getSerieType(settings: ChartStyleSettings, serie: SeriesSettings | undefined): SerieType {
  const linearSerie = serie as LinearSeriesSettings;
  return linearSerie?.serieType || getDefaultSerieType(settings.type);
}

interface SerieProps {
  selectedType: SerieType;
  onSelected: (type: SerieType) => void;
}

function BarSerie({ selectedType, onSelected }: SerieProps) {
  return (
    <IconButton sx={{ borderRadius: "unset" }} onClick={() => onSelected("Bar")}>
      <BarChartIcon
        sx={(theme) => ({
          color: selectedType === "Bar" ? theme.palette.primary.main : theme.palette.secondary.light,
        })}
      />
    </IconButton>
  );
}

function LineSerie({ selectedType, onSelected }: SerieProps) {
  return (
    <IconButton sx={{ borderRadius: "unset" }} onClick={() => onSelected("Line")}>
      <LineChartIcon
        sx={(theme) => ({
          color: selectedType === "Line" ? theme.palette.primary.main : theme.palette.secondary.light,
        })}
      />
    </IconButton>
  );
}

function AreaSerie({ selectedType, onSelected }: SerieProps) {
  return (
    <IconButton sx={{ borderRadius: "unset" }} onClick={() => onSelected("Area")}>
      <AreaChartIcon
        sx={(theme) => ({
          color: selectedType === "Area" ? theme.palette.primary.main : theme.palette.secondary.light,
        })}
      />
    </IconButton>
  );
}
