import { Box, Divider, Grid, Tab, Tabs } from "@mui/material";
import { useContext, useMemo, useState } from "react";
import ScrollableFlexContainer from "../../../../../shared/components/ScrollableFlexContainer";
import SortFieldsContainer from "../../common/sorting/SortFieldsContainer";
import { TableComponentProps } from "../../Types";
import { BuilderContext } from "../types";
import ColumnsDropFieldsContainer from "./columns/ColumnsDropFieldsContainer";
import ConditionsDropFieldsContainer from "./conditions/ConditionsDropFieldsContainer";
import FormatContainer from "./format/FormatContainer";
import RowsDropFieldsContainer from "./rows/RowsDropFieldsContainer";
import SettingsContainer from "./settings/SettingsContainer";
import ValuesDropFieldsContainer from "./values/ValuesDropFieldsContainer";

export const TableComponent = ({ ReportTypeSelector }: TableComponentProps) => {
  const { rowsArea, columnsArea, valuesArea, conditionsArea, sortArea } = useContext(BuilderContext);
  const [selectedTab, setSelectedTab] = useState(0);

  const availableFields = useMemo(() => {
    const result = rowsArea.values
      .concat(columnsArea.values)
      .filter((f) => !sortArea.values.find((v) => v.meta.name === f.meta.name));
    return result;
  }, [rowsArea.values, columnsArea.values, sortArea.values]);

  return (
    <Box sx={{ display: "flex", flex: 1, flexDirection: "column", gap: "1rem", mt: "1rem" }}>
      <Grid container px="1.3rem">
        {ReportTypeSelector}
        <Tabs value={selectedTab} sx={{ borderBottom: "none" }} onChange={(_, value) => setSelectedTab(value)}>
          <Tab label="Fields" />
          <Tab label="Settings" />
          <Tab label="Format" />
        </Tabs>
      </Grid>
      <Divider sx={{ mt: -2 }} />
      {selectedTab === 0 && (
        <ScrollableFlexContainer
          scrollContainerSx={{ display: "flex", flexDirection: "column", gap: "1.5rem", px: "1.3rem" }}
        >
          <ConditionsDropFieldsContainer area={conditionsArea} disableEqualitySelection={true} />
          <RowsDropFieldsContainer />
          <ColumnsDropFieldsContainer />
          <ValuesDropFieldsContainer
            conditionsArea={conditionsArea}
            fieldsArea={valuesArea}
            fields={rowsArea.values.concat(columnsArea.values)}
          />
          <SortFieldsContainer area={sortArea} availableFields={availableFields} />
        </ScrollableFlexContainer>
      )}

      {selectedTab === 1 && <SettingsContainer />}
      {selectedTab === 2 && <FormatContainer />}
    </Box>
  );
};

export default TableComponent;
