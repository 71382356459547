import { MenuItem, Select, Stack, Typography } from "@mui/material";
import React from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import {
  LinearSeriesSettings,
  SeriesSettings,
  ValueAxisType,
} from "../../../../../../shared/reporting/api/biClient.types";
import { SettingsArea } from "../../contexts/FieldsStateContext";
import { isLinearSettings } from "../../contexts/FieldsStateContext.types";

interface Props {
  area: SettingsArea;
  serie: SeriesSettings;
}

export default function SerieValueAxis({ area, serie }: Props) {
  const supported = React.useMemo(() => isLinearSettings(area.settings), [area.settings]);
  const valueAxisType = React.useMemo(() => getValueAxisType(serie), [serie]);

  const handleLineTypeChanged = React.useCallback(
    (value: ValueAxisType) => area.updateSerieAxis(serie.name, value),
    [area, serie]
  );

  if (!supported) {
    return <></>;
  }

  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography sx={{ color: "text.secondary" }}>Value Axis</Typography>
      <HorizontalFill />
      <Select
        value={valueAxisType}
        sx={{
          width: 200,
          ".MuiSelect-select": {
            py: 0.7,
            fontSize: 13,
          },
        }}
        onChange={(evt) => handleLineTypeChanged(evt.target.value as ValueAxisType)}
      >
        <MenuItem value={"Main"} sx={{ fontSize: 13 }}>
          Main(left)
        </MenuItem>
        <MenuItem value={"Secondary"} sx={{ fontSize: 13 }}>
          Secondary(right)
        </MenuItem>
      </Select>
    </Stack>
  );
}

export function getValueAxisType(serie: SeriesSettings | undefined): ValueAxisType {
  const linearSerie = serie as LinearSeriesSettings;
  return linearSerie?.valueAxis || "Main";
}
