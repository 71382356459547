import { Stack, Typography } from "@mui/material";
import { FieldConfiguration, ReportField } from "../../../../../shared/reporting/api/biClient.types";
import BlankZeroMenuItem from "../../common/measures/menuItems/BlankZeroMenuItem";
import CustomConditionsMenuItem from "../../common/measures/menuItems/CustomConditionsMenuItem";
import ReverseSignMenuItem from "../../common/measures/menuItems/ReverseSignMenuItem";
import SuppressBlankValues from "../../common/SuppressBlankValues";

interface Props {
  measure: ReportField;
  saveChanges: (changes: Partial<FieldConfiguration>, causeClosing: boolean) => void;
}
const OtherOptionsComponent = ({ measure, saveChanges }: Props) => (
  <Stack py={1.5}>
    <Typography variant="subtitle1" sx={{ pl: 2, py: 1 }}>
      Other
    </Typography>
    <CustomConditionsMenuItem field={measure} saveChanges={saveChanges} />
    <BlankZeroMenuItem field={measure} saveChanges={saveChanges} />
    <ReverseSignMenuItem measure={measure} saveChanges={saveChanges} />
    <SuppressBlankValues
      field={measure}
      onChanged={saveChanges}
      showExcludeZeroValues={measure.config.hideBlankValues === true}
    />
  </Stack>
);

export default OtherOptionsComponent;
