import { Grid, Typography } from "@mui/material";
import { useMemo } from "react";
import { ReportField } from "../../../../../../shared/reporting/api/biClient.types";
import { getAggregationType, getDimensionAggregationType } from "../../../utils/aggregationHelper";
import PrimaryCaption from "./PrimaryCaption";

interface Props {
  field: ReportField;
}
export default function FieldDimensionCaption({ field }: Props) {
  const caption = field.config.customLabel || field.meta.caption;
  const aggregation = getDimensionAggregationType(field);

  const CaptionElement = useMemo(() => <PrimaryCaption text={caption} />, [caption]);

  if (aggregation) {
    return (
      <Grid
        gap={0.5}
        overflow={"hidden"}
        alignItems={"center"}
        display={"grid"}
        gridTemplateColumns={"minmax(0, auto) minmax(20px, auto)"}
      >
        <Typography variant="caption" sx={{ color: (theme) => theme.palette.primary.main }} fontWeight={500} noWrap>
          {getAggregationType(aggregation)?.short} of
        </Typography>
        {CaptionElement}
      </Grid>
    );
  }

  return <>{CaptionElement} </>;
}
