import { Suspense, lazy, useMemo } from "react";
import { getVariables } from "../../../variables";

const DeveloperPanel = lazy(() => import("./DeveloperPanel"));

export function DeveloperPanelWrapper() {
  const devToolsEnabled = useMemo(() => getVariables().DEVELOPER_TOOLS === "true", []);

  if (!devToolsEnabled) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <DeveloperPanel />
    </Suspense>
  );
}
