import { Box, Divider, Stack, Typography } from "@mui/material";
import { ClientInfo, ReportTemplate, Status } from "../../../shared/reporting/api/biClient.types";
import HorizontalFill from "../../../shared/components/HorizontalFill";
import React from "react";
import BackToTemplates from "./backToTemplates/BackToTemplates";
import LiveStatus from "./status/LiveStatus";
import DraftStatus from "./status/DraftStatus";

interface Props {
  template: ReportTemplate;
  clientTitle: string;
  companies: ClientInfo[];
}

export default function TemplateActionHeader({
  template,
  children,
  clientTitle,
  companies,
}: React.PropsWithChildren<Props>) {
  return (
    <Box
      sx={{
        display: "flex",
        p: ".7rem 1.3rem",
        alignItems: "center",
        gap: 2,
      }}
    >
      <BackToTemplates companies={companies} />
      <Divider orientation="vertical" flexItem />
      <Stack gap={0.5}>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Typography variant="h6">{template.name}</Typography>
          <Box display="flex" alignItems="center">
            {template.status === Status.Live ? <LiveStatus /> : <DraftStatus />}
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Box>
            <Typography variant="caption" sx={{ color: "text.secondary" }}>
              {clientTitle}
            </Typography>
          </Box>
          {template.fundTypes.length > 0 && (
            <Divider orientation="vertical" variant="middle" flexItem sx={{ my: 0.5 }} />
          )}
          <Box>
            <Typography variant="caption" sx={{ color: "text.secondary" }}>
              {template.fundTypes.join(", ")}
            </Typography>
          </Box>
        </Box>
      </Stack>
      <HorizontalFill />
      <Box sx={{ flexGrow: 1 }}></Box>
      {children}
    </Box>
  );
}
