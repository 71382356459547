import { Button, Divider, Stack } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ReportAuthorizedBox from "../../../../shared/components/ReportAuthorizedBox";
import EditReportIcon from "../../../../shared/icons/EditReportIcon";
import { Report, ReportAccessType, ReportType } from "../../../../shared/reporting/api/biClient.types";
import { useEnhancedBiApiClientProvider } from "../../../contexts/ApiClientProviderContext";
import AnalyticsApiIcon from "../../../icons/AnalyticsApiIcon";
import { currentReportActions, selectCurrentReportAuthorizationInfo } from "../../../store/currentReportSlice";
import ReportSelector from "../../common/report-selector/ReportSelector";
import MoreAction from "../common/saving/MoreAction";
import ShareReportButton from "../common/sharing/ShareReportButton";
import ReportActionHeader from "../ReportActionHeader";
import TabularContainer from "../tabular/TabularContainer";
import SaveReportDialog from "./SaveReportDialog";
import ReportViewer from "./viewer/ReportViewer";

interface Props {
  report: Report;
}
const ViewReportPage = ({ report }: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authorizationInfo = useSelector(selectCurrentReportAuthorizationInfo);
  const { getEditReportUrl } = useEnhancedBiApiClientProvider();

  useEffect(() => {
    dispatch(currentReportActions.updateReportMode(false));
  }, [dispatch]);

  return (
    <Stack height={"100%"}>
      <ReportActionHeader
        report={undefined}
        ReportSelector={<ReportSelector requiredAccess={ReportAccessType.Read} groups={undefined} isGrouped={false} />}
        SaveReportDialogType={SaveReportDialog}
        ProductIcon={AnalyticsApiIcon}
      >
        <Stack direction={"row"} gap={1} height={32}>
          <ShareReportButton />
          <ReportAuthorizedBox
            permissions={["ManageReports"]}
            access={authorizationInfo?.access}
            requiredAccess={ReportAccessType.Write}
          >
            <Button
              variant="outlined"
              color="secondary"
              sx={(theme) => ({ svg: { color: theme.palette.secondary.light } })}
              startIcon={<EditReportIcon color="secondary" />}
              onClick={() => navigate(getEditReportUrl(report.reportId))}
            >
              Edit Report
            </Button>
            <MoreAction report={report} hideChangeGroup />
          </ReportAuthorizedBox>
        </Stack>
      </ReportActionHeader>
      <Divider />
      {report.reportType === ReportType.Tabular && (
        <TabularContainer report={report}>
          <ReportViewer />
        </TabularContainer>
      )}
    </Stack>
  );
};

export default ViewReportPage;
