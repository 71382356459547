import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { IconButton } from "@mui/material";
import { forwardRef } from "react";

interface FieldItemOptionsButtonProps {
  hideOptions?: boolean;
  showOptions?: () => void;
}

const FieldItemOptionsButton = forwardRef<HTMLButtonElement, FieldItemOptionsButtonProps>(
  ({ hideOptions, showOptions }: FieldItemOptionsButtonProps, ref) => {
    return (
      <IconButton
        ref={ref}
        onClick={showOptions}
        sx={{ height: "30px", display: hideOptions === true ? "none" : "inherit" }}
      >
        <ChevronRightIcon sx={(theme) => ({ color: theme.palette.secondary.light })} />
      </IconButton>
    );
  }
);

FieldItemOptionsButton.displayName = "FieldItemOptionsButton";

export default FieldItemOptionsButton;
