import SaveAsIcon from "@mui/icons-material/SaveAs";
import { Box } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import SplitButton, { SplitButtonOption } from "../../../../../shared/components/SplitButton";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { Report } from "../../../../../shared/reporting/api/biClient.types";
import { useEnhancedBiApiClientProvider } from "../../../../contexts/ApiClientProviderContext";
import { useLocalization } from "../../../../hooks/useLocalization";
import {
  currentReportActions,
  selectReportConfiguration,
  selectReportEditMode,
} from "../../../../store/currentReportSlice";
import { SaveAsDialogProps, SaveReportDialogProps } from "../../Types";

interface Props {
  report: Report;
  SaveReportDialogType: React.ComponentType<SaveReportDialogProps>;
  SaveAsDialogType: React.ComponentType<SaveAsDialogProps>;
}

export const SaveButton = ({ report, SaveReportDialogType, SaveAsDialogType }: Props) => {
  const { addReport, updateReport, getEditReportUrl, getViewReportUrl } = useEnhancedBiApiClientProvider();
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reportConfiguration = useSelector(selectReportConfiguration);
  const isEditMode = useSelector(selectReportEditMode);
  const { report: locale } = useLocalization();
  const [openSavingDialog, setOpenSavingDialog] = useState(false);
  const [saving, setSaving] = useState(false);
  const [openSaveAsDialog, setSaveAsDialog] = useState<boolean>();
  const isNewReport = report.reportId === undefined;

  const saveExistedReport = useCallback(async () => {
    if (reportConfiguration === undefined) return;
    setSaving(true);
    const rep = { ...report };
    rep.configuration = reportConfiguration;

    updateReport(rep)
      .then((response) => {
        if (response.success && response.data !== undefined) {
          dispatch(currentReportActions.setReport(response.data));
          sendNotification(locale.saved);
        } else {
          sendNotificationError(locale.saving_error);
        }
      })
      .catch(() => {
        sendNotificationError(locale.saving_error);
      })
      .finally(() => setSaving(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reportConfiguration,
    report,
    sendNotification,
    locale.saved,
    locale.saving_error,
    sendNotificationError,
    updateReport,
  ]);

  const onSaveReport = useCallback(() => {
    if (isNewReport) {
      setOpenSavingDialog(true);
    } else {
      saveExistedReport();
    }
  }, [isNewReport, saveExistedReport]);

  const onSaveAs = useCallback(
    (reportId: string) => navigate(isEditMode ? getEditReportUrl(reportId) : getViewReportUrl(reportId)),
    [isEditMode, navigate, getEditReportUrl, getViewReportUrl]
  );

  const secondaryOptions = useMemo(() => {
    return [{ title: "Save As...", handler: () => setSaveAsDialog(true), icon: SaveAsIcon }] as SplitButtonOption[];
  }, [setSaveAsDialog]);

  const primaryOption = useMemo(() => {
    return { title: "Save", handler: onSaveReport } as SplitButtonOption;
  }, [onSaveReport]);

  const addNewReport = async (report: Report) => {
    const resp = await addReport(report);
    if (resp.success) {
      return resp.data;
    }

    throw new Error(resp.error?.message);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexGrow: 0,
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <SplitButton
          saving={saving}
          secondaryOptions={isNewReport ? [] : secondaryOptions}
          primaryOption={primaryOption}
        />
      </Box>
      <SaveReportDialogType
        report={report}
        open={openSavingDialog}
        title="Save Report"
        saveBtnTitle="Save"
        reportConfiguration={reportConfiguration}
        onClose={() => setOpenSavingDialog(false)}
      />
      {openSaveAsDialog && report && (
        <SaveAsDialogType
          report={report}
          reportConfiguration={reportConfiguration || report.configuration}
          addReport={addNewReport}
          onChanged={(id: string) => {
            sendNotification(locale.duplicated);
            onSaveAs.call(null, id);
          }}
          onClose={() => setSaveAsDialog(false)}
        />
      )}
    </>
  );
};

export default SaveButton;
