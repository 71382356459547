import { createContext, PropsWithChildren, useContext } from "react";
import { BiAggregation, ReportField } from "../../../../../../shared/reporting/api/biClient.types";
import { defined } from "../../../../../../shared/utilities/typeHelper";

type SupportedAggregationsType = {
  aggregations: BiAggregation[];
  getSupportedAggregationTypes: (field: ReportField) => BiAggregation[];
};

export const SupportedAggregationsContext = createContext<SupportedAggregationsType | undefined>(undefined);

export const SupportedAggregationsContextProvider = (props: PropsWithChildren<SupportedAggregationsType>) => (
  <SupportedAggregationsContext.Provider value={{ ...props }}>{props.children}</SupportedAggregationsContext.Provider>
);

export const useSupportedAggregationsContext = () => {
  const context = useContext(SupportedAggregationsContext);
  return defined(context);
};
