import { ReportField } from "../../../../../shared/reporting/api/biClient.types";
import { isMeasure } from "../../utils/fieldsHelper";

export function isValueFieldHasParameters(field: ReportField | undefined): boolean {
  const result =
    field?.config.useCustomConditions === true &&
    ((isMeasure(field) && field.meta.capabilities.customConditionsAllowed) || !isMeasure(field)) &&
    field.config.customConditions &&
    field.config.customConditions.some((cc) => cc.parameter === true);

  return !result ? false : result;
}
