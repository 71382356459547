import { Grouping } from "../../../../shared/reporting/api/biClient.types";
import { Maybe } from "../../../../shared/types";

export const getDateGroupingLabel = (grouping: Grouping | undefined | null) => {
  switch (grouping) {
    case Grouping.ByMonth: {
      return "by Month";
    }
    case Grouping.ByQuarter: {
      return "by Quarter";
    }
    case Grouping.ByYear: {
      return "by Year";
    }
    case null:
    case undefined: {
      return "by Day";
    }
    default:
      return "";
  }
};

export const defaultDateGrouping = [Grouping.ByYear, Grouping.ByQuarter, Grouping.ByMonth, undefined];

export const getDisplayCaption = (caption: string, customLabel?: Maybe<string>, systemLabel?: Maybe<string>) => {
  return customLabel || systemLabel || caption;
};
