import { Box, Divider, Grid2, Tab, Tabs } from "@mui/material";
import { useMemo, useState } from "react";
import ScrollableFlexContainer from "../../../../../shared/components/ScrollableFlexContainer";
import { getAvailableSortFields } from "../../common/utilities/sortFieldStateHelper";
import ConditionsDropFieldsContainer from "../../pivot/table/conditions/ConditionsDropFieldsContainer";
import { TableComponentProps } from "../../Types";
import { useFieldsStateContext } from "../contexts/FieldsStateContext";
import SettingsContainer from "../settings/SettingsContainer";
import FieldsDropContainer from "./FieldsDropContainer";
import FormatContainer from "./FormatContainer";
import GroupsDropFieldsContainer from "./GroupsDropFieldsContainer";
import SortDropsFieldsContainer from "./SortDropsFieldsContainer";
import TableDragLayer from "./TableDragLayer";
import { getAvailableGroupFields } from "../../common/utilities/groupFieldStateHelper";

export const TableComponent = ({ ReportTypeSelector }: TableComponentProps) => {
  const { conditionsArea, sortingArea, fieldsArea, groupingArea } = useFieldsStateContext();
  const [selectedTab, setSelectedTab] = useState(0);

  const availableSortFields = useMemo(
    () => getAvailableSortFields(fieldsArea.values, sortingArea.values),
    [fieldsArea.values, sortingArea.values]
  );

  const availableGroupFields = useMemo(
    () => getAvailableGroupFields(fieldsArea.values, groupingArea.values),
    [fieldsArea.values, groupingArea.values]
  );

  return (
    <Box sx={{ display: "flex", flex: 1, flexDirection: "column", gap: "1rem", mt: "1rem" }}>
      <Grid2 px="1.3rem">
        {ReportTypeSelector}
        <Tabs value={selectedTab} sx={{ borderBottom: "none" }} onChange={(_, value) => setSelectedTab(value)}>
          <Tab label="Fields" />
          <Tab label="Settings" />
          <Tab label="Format" />
        </Tabs>
      </Grid2>
      <Divider sx={{ mt: -2 }} />
      {selectedTab === 0 && (
        <ScrollableFlexContainer
          scrollContainerSx={{ display: "flex", flexDirection: "column", gap: "1.5rem", px: "1.3rem" }}
        >
          <ConditionsDropFieldsContainer area={conditionsArea} disableEqualitySelection={true} />
          <FieldsDropContainer fieldsArea={fieldsArea} conditionsArea={conditionsArea} />
          <SortDropsFieldsContainer area={sortingArea} availableFields={availableSortFields} />
          <GroupsDropFieldsContainer area={groupingArea} availableFields={availableGroupFields} />
          <TableDragLayer />
        </ScrollableFlexContainer>
      )}
      {selectedTab === 1 && <SettingsContainer />}
      {selectedTab === 2 && <FormatContainer />}
    </Box>
  );
};

export default TableComponent;
