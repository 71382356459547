import { Stack, Typography } from "@mui/material";
import { FieldConfiguration, ItemDataType, MeasureField } from "../../../../../../shared/reporting/api/biClient.types";
import FormatSettings from "./FormatSettings";

interface Props {
  measures: MeasureField[];
  onChange: (measure: MeasureField, changes: Partial<FieldConfiguration>) => void;
}

const MeasureFieldsContainer = ({ measures, onChange }: Props) => {
  return (
    <Stack gap={1}>
      {measures
        .filter((m) => m.meta.type === ItemDataType.Numeric)
        .map((value) => (
          <FormatSettings
            key={value.config.guid}
            Label={
              <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                {value.config.customLabel || value.meta.caption}
              </Typography>
            }
            format={value.config.format}
            onUpdateFormatter={(formatter) => onChange(value, { format: formatter })}
          />
        ))}
    </Stack>
  );
};

export default MeasureFieldsContainer;
