import objectHash from "object-hash";
import React from "react";
import { useSelector } from "react-redux";
import { selectRefreshToken } from "../../../../store/currentReportSlice";
import { selectDimensions } from "../../../../store/metaDataSlice";
import { DelayObservable } from "../../../../utilities/Observers";
import { getAllFilters } from "../../pivot/preview/utilities/getAllConditions";
import { isPivotConfigurationValid } from "../../utils/isConfigurationValid";
import { useFieldsStateContext } from "../contexts/FieldsStateContext";
import useDataLoadingViewer from "./useDataLoadingViewer";

export default function useChartStateViewer() {
  const dimensions = useSelector(selectDimensions);
  const { conditionsArea, argumentsArea, valuesArea } = useFieldsStateContext();
  const { data, calculating, error, calculate, failedMeasures } = useDataLoadingViewer();
  const refreshToken = useSelector(selectRefreshToken);

  const configurationValidity = React.useMemo(() => {
    return isPivotConfigurationValid(conditionsArea.values, argumentsArea.values, valuesArea.values, dimensions);
  }, [argumentsArea.values, conditionsArea.values, dimensions, valuesArea.values]);

  const fieldsConfigured = React.useMemo(
    () => argumentsArea.values.length > 0 && valuesArea.values.length > 0,
    [argumentsArea.values, valuesArea.values]
  );

  const conditionsFiltersHash = React.useMemo(
    () => objectHash(getAllFilters(conditionsArea.values, valuesArea.values)),
    [conditionsArea.values, valuesArea.values]
  );

  React.useLayoutEffect(() => {
    const subscription = DelayObservable.subscribe(() => calculate());
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (configurationValidity.valid && fieldsConfigured) {
      DelayObservable.next(Math.random().toString());
    }
  }, [conditionsFiltersHash, refreshToken, fieldsConfigured, configurationValidity.valid]);

  return { data: data?.result, calculating, error, fieldsConfigured, configurationValidity, failedMeasures };
}
