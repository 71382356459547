import {
  ReportField,
  GroupingField,
  DimensionField,
  ItemDataType,
} from "../../../../../shared/reporting/api/biClient.types";
import { distinctBy } from "../../../../../shared/utilities/arrayHelper";
import { referenceDateFields } from "../../tabular/utilities/referenceDateFields";
import { isNotAggregatedDimension } from "../../utils/fieldsHelper";

export const getAvailableGroupFields = (values: ReportField[], groupAreaValues: GroupingField[]) => {
  const filtered = values.filter(
    (f): f is DimensionField =>
      isNotAggregatedDimension(f) &&
      (f.meta.type !== ItemDataType.Date || referenceDateFields.includes(f.meta.name)) &&
      !groupAreaValues.find((v) => v.name === f.config.guid)
  );

  return distinctBy(filtered, (f) => f.meta.name);
};
