import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BuildTabularReportRequest, ColumnSorting } from "../../../../api/biApi.types";
import {
  currentReportActions,
  selectCacheSessionId,
  selectCurrentReport,
  selectSessionId,
} from "../../../../store/currentReportSlice";
import { selectLogQueries } from "../../../../store/devToolsSlice";
import { isDimensionBased } from "../../utils/fieldsHelper";
import { useFieldsStateContext } from "../contexts/FieldsStateContext";
import { createTabularDataRequest } from "../utilities/createTabularDataRequest";
import { getSorting } from "../utilities/gridStateHelper";

const MaximumExportRecordsCount = 10000;

export default function useRequestConfigBuilder() {
  const { conditionsArea, sortingArea, fieldsArea, groupingArea, settingsArea } = useFieldsStateContext();

  const dispatch = useDispatch();
  const report = useSelector(selectCurrentReport);
  const sessionId = useSelector(selectSessionId);
  const cacheSessionId = useSelector(selectCacheSessionId);
  const isQueryLoggingActive = useSelector(selectLogQueries);

  const sorting = React.useMemo(
    () => getSorting(sortingArea.values, fieldsArea.values),
    [sortingArea.values, fieldsArea.values]
  );

  const requestConfig = React.useMemo(() => {
    const sorts = sorting
      .map((s) => {
        const field = fieldsArea.values.find((v) => v.config.guid === s.columnName);
        if (!isDimensionBased(field)) return undefined;
        return { dimensionName: field.meta.name, sortAsc: s.direction === "asc" } as ColumnSorting;
      })
      .filter((cs): cs is ColumnSorting => !!cs);
    const request: BuildTabularReportRequest = {
      ...createTabularDataRequest(
        conditionsArea.values,
        fieldsArea.values,
        sorts,
        groupingArea.values,
        settingsArea.settings
      ),
      sessionId,
      cacheSessionId,
      withDrilldown: true,
      reportId: report?.reportId ?? "",
      calcTotalCount: false,
      calcGroupTotals: true,
      take: MaximumExportRecordsCount,
      skip: 0,
      useQueryLogging: isQueryLoggingActive === true,
    };
    return request;
  }, [
    sorting,
    conditionsArea.values,
    fieldsArea.values,
    groupingArea.values,
    settingsArea.settings,
    sessionId,
    cacheSessionId,
    report?.reportId,
    isQueryLoggingActive,
  ]);

  React.useEffect(() => {
    dispatch(currentReportActions.updateReportRequest(requestConfig));
  }, [requestConfig, dispatch]);

  return { requestConfig };
}

export type TabularRequestReturnType = ReturnType<typeof useRequestConfigBuilder>;
