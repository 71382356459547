import { Typography } from "@mui/material";
import React from "react";
import SwitchField from "../../../common/SwitchField";
import { isDimensionBased } from "../../utils/fieldsHelper";
import { useFieldsStateContext } from "../contexts/FieldsStateContext";
import ExpandeGroup from "./ExpandeGroup";
import StyleItem from "./StyleItem";

export default function GroupSettings() {
  const { fieldsArea, groupingArea } = useFieldsStateContext();

  const grouping = React.useMemo(() => groupingArea.values, [groupingArea.values]);
  const dimensionFields = React.useMemo(() => fieldsArea.values.filter(isDimensionBased), [fieldsArea.values]);

  return (
    <>
      {grouping.length > 0 && (
        <ExpandeGroup title="Grouped Columns">
          {grouping.map((group, index) => (
            <StyleItem key={group.name} showBorder={grouping.length > 1 && index < grouping.length - 1}>
              <Typography variant="subtitle2" fontWeight={500} sx={{ color: "text.primary" }} pb={1}>
                {dimensionFields.find((f) => f.config.guid === group.name)?.meta.caption}
              </Typography>
              <SwitchField
                label="Show Subtotals"
                checked={!group.hideSummaries}
                onChange={(hideSummaries) => groupingArea.updateGroup(group, { hideSummaries: !hideSummaries })}
                typographyProps={{ sx: { color: "text.secondary" } }}
              />
            </StyleItem>
          ))}
        </ExpandeGroup>
      )}
    </>
  );
}
