import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { useVirtualizer } from "@tanstack/react-virtual";
import React, { forwardRef, ReactNode, useRef } from "react";
import { calculateNumberColumnWidth } from "./helpers/codeHelper";

interface PrettyJsonDisplayProps {
  rows: ReactNode[][];
}

const StyledPre = styled("pre")({ overflowX: "auto", margin: 0 });

const Pre = forwardRef<HTMLPreElement, React.HTMLAttributes<HTMLPreElement>>((props, ref) => (
  <StyledPre ref={ref} {...props} />
));

Pre.displayName = "Pre";

const PrettyCodeDisplay = ({ rows }: PrettyJsonDisplayProps) => {
  const parentRef = useRef<HTMLPreElement>(null);
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 18,
  });

  const numberColumnWidth = calculateNumberColumnWidth(rows.length * 10);

  return (
    <Pre ref={parentRef} style={{ height: "100%", overflow: "auto", zIndex: 2 }}>
      <Box className="layout" sx={{ height: "100%", position: "absolute" }}>
        <Box
          sx={{
            width: numberColumnWidth,
            bgcolor: grey[100],
            borderRight: `1px solid #E5E6E9`,
            height: "100%",
          }}
        ></Box>
      </Box>
      <Box
        sx={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: "100%",
          position: "relative",
          "& .row": {
            "& .row__number, & .row__beginning, & .row__end": {
              width: numberColumnWidth,
              display: "inline-block",
              textAlign: "center",
              mr: 3,
              color: "text.disabled",
            },
          },
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualItem) => (
          <Box
            component={"span"}
            key={virtualItem.key}
            data-index={virtualItem.index}
            ref={rowVirtualizer.measureElement}
            className={"row"}
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              transform: `translateY(${virtualItem.start}px)`,
            }}
          >
            {(rows[virtualItem.index] || []).map((item, i) => (
              <React.Fragment key={i}>{item}</React.Fragment>
            ))}
          </Box>
        ))}
      </Box>
    </Pre>
  );
};

export default PrettyCodeDisplay;
