import { Box, Button, Divider, Stack } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import ReportAuthorizedBox from "../../../shared/components/ReportAuthorizedBox";
import EditReportIcon from "../../../shared/icons/EditReportIcon";
import { ReportAccessType, ReportType } from "../../../shared/reporting/api/biClient.types";
import { useGroupsContext } from "../../../shared/reporting/components/groups/contexts/GroupsContext";
import { useEnhancedBiApiClientProvider } from "../../contexts/ApiClientProviderContext";
import ReportingIcon from "../../icons/ReportingIcon";
import {
  currentReportActions,
  selectCurrentReport,
  selectCurrentReportAuthorizationInfo,
} from "../../store/currentReportSlice";
import ReportSelector from "../common/report-selector/ReportSelector";
import ReportActionHeader from "./ReportActionHeader";
import ChartViewerContainer from "./chart/ChartViewerContainer";
import { ExportStateContextProvider } from "./common/contexts/ExportStateContext";
import { useExportState } from "./common/hooks/exportState";
import ExportAction from "./common/saving/ExportAction";
import MoreAction from "./common/saving/MoreAction";
import SaveReportDialog from "./common/saving/SaveReportDialog";
import ShareReportButton from "./common/sharing/ShareReportButton";
import PivotViewerContainer from "./pivot/PivotViewerContainer";
import TabularViewerContainer from "./tabular/TabularViewerContainer";
import { isChartReport } from "./tabular/utilities/isChartReport";

export default function ViewReportPage() {
  const { groups } = useGroupsContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const report = useSelector(selectCurrentReport);
  const authorizationInfo = useSelector(selectCurrentReportAuthorizationInfo);
  const exportState = useExportState();
  const { getEditReportUrl } = useEnhancedBiApiClientProvider();

  React.useEffect(() => {
    dispatch(currentReportActions.updateReportMode(false));
  }, [dispatch]);

  if (report === undefined) return <></>;

  return (
    <ExportStateContextProvider {...exportState}>
      <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
        <ReportActionHeader
          report={undefined}
          ReportSelector={<ReportSelector requiredAccess={ReportAccessType.Read} groups={groups} isGrouped={true} />}
          SaveReportDialogType={SaveReportDialog}
          ProductIcon={ReportingIcon}
        >
          <Stack direction={"row"} gap={1} height={32}>
            <ShareReportButton />
            <ExportAction />
            <ReportAuthorizedBox
              permissions={["ManageReports"]}
              access={authorizationInfo?.access}
              requiredAccess={ReportAccessType.Write}
            >
              <Button
                variant="outlined"
                color="secondary"
                sx={(theme) => ({ svg: { color: theme.palette.secondary.light } })}
                startIcon={<EditReportIcon color="secondary" />}
                onClick={() => navigate(getEditReportUrl(report.reportId))}
              >
                Edit Report
              </Button>
              <MoreAction report={report} />
            </ReportAuthorizedBox>
          </Stack>
        </ReportActionHeader>
        <Divider />

        {(!report.reportType || report.reportType === ReportType.Pivot) && <PivotViewerContainer report={report} />}
        {report?.reportType === ReportType.Tabular && <TabularViewerContainer report={report} />}
        {isChartReport(report) && <ChartViewerContainer report={report} />}
      </Box>
    </ExportStateContextProvider>
  );
}
