import { Grid } from "@mui/material";
import React, { BaseSyntheticEvent, KeyboardEvent } from "react";
import { Subject, Subscription, debounceTime } from "rxjs";
import "./FormulaInput.css";
import { FormulaInputActions, FormulaInputState } from "./hooks/formulaInputState";
import { CustomMeasureReturnType } from "./hooks/useCustomMeasure";
import { getCaretIndex, setCaretIndex } from "./utilities/caretPosition";
import { FunctionFormulaClass } from "./utilities/formulaNodeStyling";
import { INode, formulaParser } from "./utilities/formulaParser";
import { TokenIterator, tokenIterator } from "./utilities/tokenIterator";
import { Token, TokenType } from "./utilities/tokenizer";

type ParsingResult = {
  success: boolean;
  rootNode?: INode;
  iterator?: TokenIterator;
  handleIntellisense?: boolean;
};

interface Props {
  name?: string;
  defaultFormula?: string;
  state: CustomMeasureReturnType;
  editable: boolean;
  formulaState: FormulaInputState;
  actions: FormulaInputActions;
  onSuccess: () => void;
  onFailed: (tokens: Token[]) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onInput?: () => void;
}

interface State {
  ref: HTMLElement | null;
  currentToken?: Token;
}

export class FormulaInput extends React.Component<Props, State> {
  private inputStream = new Subject<ParsingResult>();
  private inputStreamSubscription?: Subscription = undefined;

  constructor(props: Props) {
    super(props);
    this.state = { ref: null };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  override shouldComponentUpdate(nextProps: Readonly<Props>, _: Readonly<State>): boolean {
    if (this.props.formulaState.inserted !== nextProps.formulaState.inserted) {
      const text = this.state.ref?.textContent === undefined ? null : this.state.ref?.textContent;
      this.handleInputChangeCore(text, false);
    }

    return false;
  }

  override componentWillUnmount(): void {
    if (this.inputStreamSubscription !== undefined) {
      this.inputStreamSubscription.unsubscribe();
    }
  }

  override componentDidMount(): void {
    this.inputStreamSubscription = this.inputStream.pipe(debounceTime(100)).subscribe(this.renderContent.bind(this));
    if (this.props.defaultFormula !== undefined) {
      this.handleInputChangeCore(this.props.defaultFormula, false);
    }
  }

  handleKey(evt: KeyboardEvent<HTMLDivElement>) {
    if (evt.altKey || evt.ctrlKey || evt.metaKey || evt.shiftKey) return;

    if (evt.key === "Escape") {
      evt.stopPropagation();
      evt.preventDefault();
      this.props.actions.close();
      return;
    }

    if (evt.key === "Enter" || evt.key === "Tab") {
      evt.preventDefault();
      this.props.actions.select();
    }
    if (evt.key === "ArrowDown") {
      evt.preventDefault();
      this.props.actions.next();
    }
    if (evt.key === "ArrowUp") {
      evt.preventDefault();
      this.props.actions.previous();
    }
    if (evt.key === "ArrowLeft" || evt.key === "ArrowRight") {
      this.props.actions.close();
    }
  }

  handleInputChange(evt: BaseSyntheticEvent) {
    this.props.onInput?.();
    const text = evt.target.textContent;
    this.handleInputChangeCore(text, true);
  }

  handleInputChangeCore(text: string | null, handleIntellisense: boolean) {
    if (text === null || text === "" || text === undefined) {
      this.inputStream.next({ success: true });
      this.props.state.actions.update({ formula: undefined });
      return;
    }

    const iterator = tokenIterator(text);
    try {
      const rootNode = formulaParser(iterator);
      this.inputStream.next({ success: true, rootNode, iterator, handleIntellisense: handleIntellisense });
    } catch {
      this.inputStream.next({ success: false, iterator, handleIntellisense: handleIntellisense });
    }
    this.props.state.actions.update({ formula: text });
  }

  renderContent(parsingResult: ParsingResult) {
    if (!this.state.ref) return;
    const oldCaretIndex = this.clearContent(this.state.ref);

    if (parsingResult === undefined || parsingResult.iterator === undefined) {
      this.props.state.actions.setRootNode();
      if (parsingResult.success) {
        this.props.onSuccess();
      }
      return;
    }

    this.customRender(parsingResult.iterator, oldCaretIndex);
    if (parsingResult.success) {
      this.props.state.actions.setRootNode(parsingResult.rootNode);
      this.props.onSuccess();
    } else {
      this.props.onFailed(parsingResult.iterator.all());
    }
    if (parsingResult.handleIntellisense) {
      this.handleIntellisense(parsingResult.iterator);
    }
  }

  handleIntellisense(iterator: TokenIterator) {
    if (this.state.ref === null) return;
    const caretIndex = getCaretIndex(this.state.ref);
    const tokenByCursor = iterator
      .all()
      .find((token) => token.startIndex <= caretIndex && token.endIndex >= caretIndex);
    if (tokenByCursor === undefined) {
      this.props.state.actions.update({ currentToken: undefined });
    } else if (tokenByCursor.tokenType === TokenType.Identifier) {
      this.props.state.actions.update({ currentToken: tokenByCursor });
    }
  }

  customRender(iterator: TokenIterator, oldCaretIndex: number) {
    if (!this.state.ref) return;

    const nodes: (string | Node)[] = [];

    let caretNode: Node | undefined = undefined;
    let caretIndex: number | undefined = undefined;
    iterator.all().forEach((token) => {
      if (token.tokenType === TokenType.Eof) return;
      const node = document.createElement("span");
      node.innerText = `${token.value}`;
      if (token.tokenType === TokenType.Identifier) {
        node.classList.add("identifier-node");
        node.onmouseenter = () => {
          this.props.state.actions.highlightNode(token);
          if (token.element?.classList.contains(FunctionFormulaClass)) {
            this.props.state.actions.functionHovered(token);
          }
        };
        node.onmouseleave = () => {
          this.props.state.actions.highlightNode(undefined);
          if (token.element?.classList.contains(FunctionFormulaClass)) {
            this.props.state.actions.functionHovered(undefined);
          }
        };
        node.onclick = () => {
          this.props.state.actions.setSelectedFormulaParameter(token);
        };
      }
      token.element = node;

      if (token.error) {
        node.style.color = "#D32F2F";
      }

      if (oldCaretIndex > token.startIndex && oldCaretIndex <= token.endIndex) {
        caretNode = node;
        caretIndex = oldCaretIndex - token.startIndex;
      }

      nodes.push(node);
    });

    this.state.ref.append(...nodes);
    if (caretNode !== undefined && caretIndex !== undefined) {
      setCaretIndex(caretNode, caretIndex);
    }
  }

  clearContent(node: HTMLElement): number {
    const caretIndex = getCaretIndex(node);
    node.innerHTML = "";
    return caretIndex;
  }

  override render(): React.ReactNode {
    return (
      <Grid
        item
        component="p"
        ref={(ref: HTMLParagraphElement) => this.setState({ ref })}
        defaultValue={this.props.defaultFormula}
        sx={(theme) => ({
          display: "inline",
          flex: 1,
          px: theme.spacing(0.625),
          paddingTop: theme.spacing(2),
          my: 0,
          "&:focus": {
            outline: "0px solid transparent",
          },
          textWrap: "nowrap",
          lineHeight: "24px",
        })}
        // turn off Grammarly to apply its own spellchecking
        data-gramm="false"
        spellCheck={false}
        contentEditable={this.props.editable}
        onInput={this.handleInputChange.bind(this)}
        onKeyDown={this.handleKey.bind(this)}
        onBlur={() => {
          this.props.actions.close();
          this.props.onBlur?.();
        }}
        onFocus={() => this.props.onFocus?.()}
        suppressContentEditableWarning={true}
      />
    );
  }
}

export default FormulaInput;
