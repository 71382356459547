import { Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import { SettingsArea } from "../../contexts/FieldsStateContext";

interface Props {
  area: SettingsArea;
}

export default function HorizontalAlignmentItem({ area }: Props) {
  const alignment = React.useMemo(() => getHorizontalAlignment(area), [area]);

  return (
    <Stack flexDirection="row" alignItems="center">
      <Typography sx={{ color: "text.secondary" }}>Horizontal Alignment</Typography>
      <HorizontalFill />
      <ToggleButtonGroup
        exclusive
        color="primary"
        value={alignment}
        onChange={(_, value) => {
          if (value !== null) area.updateLegend({ horizontalAlignment: value });
        }}
      >
        <ToggleButton value="left" sx={{ py: 0.3, fontWeight: 400 }}>
          Left
        </ToggleButton>
        <ToggleButton value="center" sx={{ py: 0.3, fontWeight: 400 }}>
          Center
        </ToggleButton>
        <ToggleButton value="right" sx={{ py: 0.3, fontWeight: 400 }}>
          Right
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  );
}

export function getHorizontalAlignment(area: SettingsArea) {
  return area.settings?.legend?.horizontalAlignment || "right";
}
