import objectHash from "object-hash";
import { PropsWithChildren, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BiAggregation,
  DimensionDescriptor,
  DimensionField,
  ItemDataType,
  MeasureDescriptor,
  PivotConfiguration,
  Report,
  ReportConfiguration,
  ReportField,
} from "../../../../shared/reporting/api/biClient.types";
import { currentReportActions, selectReportConfiguration } from "../../../store/currentReportSlice";
import { selectDimensions, selectMeasures } from "../../../store/metaDataSlice";
import { SupportedAggregationsContextProvider } from "../common/fields/contexts/ExportStateContext";
import useCrossFiltering from "../common/hooks/useCrossFiltering";
import useDeferredDictionaryLoading from "../common/hooks/useDeferredDictionaryLoading";
import { updateCalculateByFieldForCustomMeasures } from "../utils/MeasureUtils";
import useFieldsState, { FieldsStateReturnType } from "./hooks/useFieldsState";
import { BuilderContext, BuilderContextType } from "./types";
import {
  configurationToColumns,
  configurationToConditions,
  configurationToRows,
  configurationToSettings,
  configurationToSorts,
  configurationToValues,
  createReportConfiguration,
} from "./utilities/Configurations";

interface Props {
  report: Report;
}

export default function PivotContainer({ report, children }: PropsWithChildren<Props>) {
  const dispatch = useDispatch();
  const dimensions = useSelector(selectDimensions);
  const measures = useSelector(selectMeasures);
  const reportConfiguration = useSelector(selectReportConfiguration);
  const fieldsState = useFieldsState();
  const sortingHash = useMemo(() => objectHash(fieldsState.sorts), [fieldsState.sorts]);
  const fieldsStateRef = useRef(fieldsState);
  fieldsStateRef.current = fieldsState;

  useEffect(() => {
    setReportConfiguration(report, fieldsState, dimensions, measures, reportConfiguration);
    dispatch(currentReportActions.refreshSession());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report.reportId]);

  useEffect(() => updateCalculateByFieldForCustomMeasures(fieldsStateRef.current, measures), [measures]);

  useEffect(() => {
    const configuration = createReportConfiguration(
      fieldsState.conditions,
      fieldsState.rows,
      fieldsState.columns,
      fieldsState.values,
      fieldsState.sorts,
      fieldsState.settings
    );
    dispatch(currentReportActions.updateReportConfiguration(configuration));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fieldsState.conditions,
    fieldsState.rows,
    fieldsState.columns,
    fieldsState.values,
    fieldsState.settings,
    sortingHash,
  ]);

  const context = useMemo(() => buildContext(fieldsState), [fieldsState]);

  useCrossFiltering(context.conditionsArea);
  useDeferredDictionaryLoading(context.conditionsArea);

  return (
    <BuilderContext.Provider value={context}>
      <SupportedAggregationsContextProvider
        aggregations={supportedAggregations}
        getSupportedAggregationTypes={getSupportedAggregationTypes}
      >
        {children}
      </SupportedAggregationsContextProvider>
    </BuilderContext.Provider>
  );
}

const supportedAggregations = [
  BiAggregation.Average,
  BiAggregation.Count,
  BiAggregation.CountDistinct,
  BiAggregation.Max,
  BiAggregation.Min,
  BiAggregation.Sum,
];

const getSupportedAggregationTypes = (field: ReportField) => {
  const items = [BiAggregation.Count, BiAggregation.CountDistinct];

  if (field.meta.type === ItemDataType.Numeric) {
    items.push(BiAggregation.Average, BiAggregation.Min, BiAggregation.Max, BiAggregation.Sum);
  }

  return items;
};

function setReportConfiguration(
  report: Report,
  fieldsState: FieldsStateReturnType,
  dimensions: DimensionDescriptor[],
  measures: MeasureDescriptor[],
  currentReportConfiguration: ReportConfiguration | undefined
) {
  const configuration = (currentReportConfiguration || report.configuration) as PivotConfiguration;
  if (!configuration) {
    return;
  }
  if (configuration.conditions) {
    const result = configurationToConditions(configuration.conditions, dimensions);
    fieldsState.setConditions(result);
  }
  if (configuration.rows) {
    const result = configurationToRows(configuration.rows, dimensions);
    fieldsState.setRows(result);
  }
  if (configuration.columns) {
    const result = configurationToColumns(configuration.columns, dimensions);
    fieldsState.setColumns(result);
  }
  if (configuration.values) {
    const result = configurationToValues(configuration.values, measures, dimensions);
    fieldsState.setMeasures(result);
  }
  if (configuration.sort) {
    const result = configurationToSorts(configuration.sort, dimensions);
    fieldsState.setSorting(result);
  }
  if (configuration.settings) {
    const result = configurationToSettings(configuration.settings);
    fieldsState.setSettings(result);
  }
}

function buildContext(fieldsState: FieldsStateReturnType): BuilderContextType {
  const context: BuilderContextType = {
    conditionsArea: {
      values: fieldsState.conditions,
      addItem: fieldsState.addCondition,
      removeItem: fieldsState.removeCondition,
      moveItem: fieldsState.moveCondition,
      updateItem: fieldsState.updateCondition,
      updateItemConfig: fieldsState.updateConditionConfig,
    },
    rowsArea: {
      values: fieldsState.rows,
      addItem: fieldsState.addRow,
      removeItem: (item: DimensionField) => {
        fieldsState.removeRow(item);
        fieldsState.removeSortingByMeta(item.meta);
      },
      moveItem: fieldsState.moveRow,
      updateItem: fieldsState.updateRow,
      updateItemConfig: fieldsState.updateRowConfig,
    },
    columnsArea: {
      values: fieldsState.columns,
      addItem: fieldsState.addColumn,
      removeItem: (item: DimensionField) => {
        fieldsState.removeColumn(item);
        fieldsState.removeSortingByMeta(item.meta);
      },
      moveItem: fieldsState.moveColumn,
      updateItem: fieldsState.updateColumn,
      updateItemConfig: fieldsState.updateColumnConfig,
    },
    valuesArea: {
      values: fieldsState.values,
      addItem: fieldsState.addMeasure,
      removeItem: fieldsState.removeMeasure,
      moveItem: fieldsState.moveMeasure,
      updateItem: fieldsState.updateMeasure,
      updateItemConfig: fieldsState.updateMeasureConfig,
    },
    sortArea: {
      values: fieldsState.sorts,
      addItem: fieldsState.addSorting,
      removeItem: fieldsState.removeSorting,
      moveItem: fieldsState.moveSorting,
      updateItem: fieldsState.updateSorting,
      updateItemConfig: fieldsState.updateSortingConfig,
      setSorting: fieldsState.setSorting,
    },
    settingsArea: {
      settings: fieldsState.settings,
      update: fieldsState.updateSettings,
    },
  };
  return context;
}
