import { useCallback } from "react";
import { DimensionField } from "../../../../../shared/reporting/api/biClient.types";
import { SortingFieldsArea } from "../../pivot/types";
import SelectFieldInput from "./SelectFieldInput";

interface Props {
  area: SortingFieldsArea;
  availableFields: DimensionField[];
}
export const SelectSortFieldInput = ({ area, availableFields }: Props) => {
  const addFieldToSort = useCallback((field: DimensionField) => area.addItem(field, false, []), [area]);

  return <SelectFieldInput onAddField={addFieldToSort} availableFields={availableFields} />;
};

export default SelectSortFieldInput;
