import { Grid, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useCallback } from "react";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import {
  FieldConfiguration,
  FieldConfigurationType,
  IrrCashFlowFrequency,
  MeasureField,
} from "../../../../../../shared/reporting/api/biClient.types";

interface Props {
  measure: MeasureField;
  onClose: () => void;
  saveChanges: (changes: Partial<FieldConfiguration>, causeClosing: boolean) => void;
}

export default function CashFlowFrequencyMenuItem({ measure, saveChanges, onClose }: Props) {
  if (measure.config.type === FieldConfigurationType.Irr) {
    return (
      <CashFlowFrequency
        frequency={measure.config.cashFlowFrequency || IrrCashFlowFrequency.Daily}
        saveChanges={saveChanges}
        onClose={onClose}
      />
    );
  }
  return null;
}

function CashFlowFrequency({
  frequency,
  saveChanges,
  onClose,
}: Pick<Props, "saveChanges" | "onClose"> & { frequency: IrrCashFlowFrequency }) {
  const handleChange = useCallback(
    (_: React.MouseEvent<HTMLElement>, newValue: IrrCashFlowFrequency | null) => {
      if (frequency === newValue || newValue === null) {
        onClose();
      } else {
        saveChanges({ cashFlowFrequency: newValue }, false);
      }
    },
    [frequency, onClose, saveChanges]
  );

  return (
    <Grid container sx={{ flexDirection: "row", alignItems: "center", flexWrap: "nowrap" }}>
      <Typography noWrap>Cash flow frequency</Typography>
      <HorizontalFill />
      <ToggleButtonGroup
        exclusive
        color="primary"
        value={frequency}
        fullWidth
        sx={{ width: 208 }}
        onChange={handleChange}
      >
        <ToggleButton value={IrrCashFlowFrequency.Daily} sx={{ py: 0.3, fontWeight: 400 }}>
          {IrrCashFlowFrequency.Daily}
        </ToggleButton>
        <ToggleButton value={IrrCashFlowFrequency.Monthly} sx={{ py: 0.3, fontWeight: 400 }}>
          {IrrCashFlowFrequency.Monthly}
        </ToggleButton>
        <ToggleButton value={IrrCashFlowFrequency.Quarterly} sx={{ py: 0.3, fontWeight: 400 }}>
          {IrrCashFlowFrequency.Quarterly}
        </ToggleButton>
      </ToggleButtonGroup>
    </Grid>
  );
}
