import { useSelector } from "react-redux";
import { FieldConfiguration, ReportField, ReportType } from "../../../../../../../shared/reporting/api/biClient.types";
import { selectCurrentReport } from "../../../../../../store/currentReportSlice";
import SwitchMenuItem from "../../../../common/measures/menuItems/SwitchMenuItem";

interface Props {
  field: ReportField;
  saveChanges: (changes: Partial<FieldConfiguration>, causeClosing: boolean) => void;
}

export default function PivotFieldMenuItem(props: Props) {
  const { field, saveChanges } = props;
  const report = useSelector(selectCurrentReport);

  if (report?.reportType !== ReportType.Pivot) return <></>;

  return (
    <SwitchMenuItem
      caption="Pivot Field"
      value={!field.config.standalone}
      onClick={() => saveChanges({ standalone: !field.config.standalone }, false)}
    />
  );
}
