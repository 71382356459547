import Chart, { Series } from "devextreme-react/chart";
import { useCallback } from "react";
import { getMeasureCaption } from "../pivot/table/values/helper";
import ChartCore, { getBasicLegend, getBasicSerieLabel, getDxSerieType } from "./ChartCore";
import { ChartProps } from "./ChartProps";
import { useFieldsStateContext } from "./contexts/FieldsStateContext";
import { getBarWidthInPixels } from "./style/serie/BarWidthItem";
import { getValueAxisType } from "./style/serie/SerieValueAxis";
import { getShowPoints } from "./style/serie/ShowPointsItem";

export default function Bar(props: ChartProps) {
  const { valuesArea, settingsArea } = useFieldsStateContext();

  const updateRef = useCallback(
    (ref: Chart) => {
      props.exportFunc(ref?.instance.exportTo.bind(ref.instance));
    },
    [props]
  );

  const renderSeries = () => {
    const argument = props.args[0];
    if (argument === undefined) return <></>;
    return props.values.map((value) => {
      const serie = settingsArea.settings.series?.find((s) => s.name === value.config.guid);
      return (
        <Series
          key={value.config.guid}
          argumentField={argument.meta.name}
          valueField={value.config.guid}
          name={value.config.guid}
          axis={getValueAxisType(serie)}
          type={getDxSerieType(settingsArea, serie)}
          label={getBasicSerieLabel(value.config.guid, settingsArea, valuesArea, props.data)}
          barPadding={0}
          barWidth={getBarWidthInPixels(serie)}
          point={{ visible: getShowPoints(serie) }}
        ></Series>
      );
    });
  };

  return (
    <ChartCore {...props}>
      <Chart
        ref={updateRef}
        dataSource={props.dataSource}
        legend={{
          ...getBasicLegend(settingsArea),
          customizeText: ({ seriesName }) => getMeasureCaption(props.values.find((v) => v.config.guid === seriesName)),
        }}
      >
        {renderSeries()}
      </Chart>
    </ChartCore>
  );
}
