import { ItemDataType, ReportField } from "../../../../shared/reporting/api/biClient.types";
import FieldCaptionWrapper from "../common/fields/field-captions/FieldCaptionWrapper";
import FieldDimensionCaption from "../common/fields/field-captions/FieldDimensionCaption";
import { referenceDateFields } from "../tabular/utilities/referenceDateFields";
import { getDateGroupingLabel } from "./captionsHelper";
import { dateRangeToShortString } from "./dateRangeToString";
import { getDimensionAggregationType } from "./aggregationHelper";

export const formatDimensionFieldCaption = (field: ReportField) => {
  const aggregation = getDimensionAggregationType(field);
  const subCaption = aggregation ? dateRangeToShortString(field.config.dateRange) : getGroupingCaption(field);

  return <FieldCaptionWrapper Caption={<FieldDimensionCaption field={field} />} subCaption={subCaption} />;
};

/**
 * This function is used to format the dimension field caption in the fields list (Tabular)
 * When the field is a reference date field (these are Posting Date and Transaction Date dimensions), the subCaption will be the grouping label
 * Otherwise, the subCaption will be empty
 */
export const formatDimensionInFieldsCaption = (field: ReportField) => {
  const aggregation = getDimensionAggregationType(field);
  const subCaption = aggregation
    ? dateRangeToShortString(field.config.dateRange)
    : referenceDateFields.includes(field.meta.name)
      ? getGroupingCaption(field)
      : "";

  return <FieldCaptionWrapper Caption={<FieldDimensionCaption field={field} />} subCaption={subCaption} />;
};

export const getGroupingCaption = (field: ReportField) => {
  // if (field.config.aggregation)
  if (field?.meta.type === ItemDataType.Date) {
    return getDateGroupingLabel(field.config.grouping);
  }
  return "";
};
