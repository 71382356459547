import { LoadingButton } from "@mui/lab";
import { Alert, Button, DialogActions, Stack, useTheme } from "@mui/material";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import PromptDialog from "../../../../shared/components/dialog/PromptDialog";
import PromptDialogHeader from "../../../../shared/components/dialog/PromptDialogHeader";
import { useNotificationContext } from "../../../../shared/contexts/NotificationContext";
import sharedBiClient from "../../../../shared/reporting/api/biClient";
import { ClientInfo, ReportTemplate } from "../../../../shared/reporting/api/biClient.types";
import DataSource from "../../../../shared/reporting/components/reportTemplates/DataSources";
import { useLocalization } from "../../../hooks/useLocalization";
import { currentReportActions } from "../../../store/currentReportSlice";

type UpdateDataSourceModel = Pick<ReportTemplate, "dataSource">;
interface Props {
  template: ReportTemplate;
  companies: ClientInfo[];
  onClose: () => void;
  onSaved: (dataSource: string, dataSourceChanged?: boolean) => void;
}

export default function DataSourceDialog({ template, onClose, onSaved, companies }: Props) {
  const { palette } = useTheme();
  const { report_templates: locale } = useLocalization();
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const [newDataSource, setNewDataSource] = useState<UpdateDataSourceModel>(() => ({
    dataSource:
      companies.find((c) => c.clientCode === template.dataSource)?.clientCode || companies.at(0)?.clientCode || "",
  }));

  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string>();

  const onSave = useCallback(async () => {
    setIsSaving(true);
    try {
      const response = await sharedBiClient.updateReportTemplateDataSource({
        dataSource: newDataSource.dataSource,
        templateId: template.reportId,
      });
      if (response.success && response.data) {
        dispatch(currentReportActions.updateReport({ ...response.data }));
        sendNotification(locale.saved);
        onSaved(response.data.dataSource);
      } else {
        setError(locale.saving_error);
        sendNotificationError(locale.saving_error);
      }
    } catch {
      setError(locale.saving_error);
      sendNotificationError(locale.saving_error);
    } finally {
      setIsSaving(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newDataSource, onSaved, template.reportId]);

  return (
    <PromptDialog open={true} sx={{ width: 517, minHeight: 284, p: 0 }}>
      <PromptDialogHeader text="Change Data Source" disabled={isSaving} onClose={onClose} />
      {error && (
        <Alert sx={{ mb: ".5rem" }} severity="error">
          {locale.change_datasource_error}
        </Alert>
      )}
      <Stack sx={{ px: 3, py: 2, flex: 1 }}>
        <DataSource
          title="Data Source"
          disclaimer={locale.change_datasource_disclaimer}
          dataSource={newDataSource.dataSource}
          companies={companies}
          onChange={(company) => setNewDataSource((prev) => ({ ...prev, dataSource: company }))}
          sx={{ " #disclaimer": { color: palette.text.secondary } }}
        />
      </Stack>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button variant="text" color="secondary" disabled={isSaving} onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton variant="contained" disabled={isSaving} loading={isSaving} onClick={onSave}>
          Save
        </LoadingButton>
      </DialogActions>
    </PromptDialog>
  );
}
