import { Stack } from "@mui/material";
import { useCallback, useMemo } from "react";
import ScrollableFlexContainer from "../../../../../shared/components/ScrollableFlexContainer";
import { FieldConfiguration, ReportField } from "../../../../../shared/reporting/api/biClient.types";
import MeasureFieldsContainer from "../../common/fields/format/MeasureFieldsContainer";
import { isDimensionBased, isMeasure } from "../../utils/fieldsHelper";
import { useFieldsStateContext } from "../contexts/FieldsStateContext";
import TextFieldContainer from "./TextFieldsContainer";
import DimensionFormattingContainer from "../../common/fields/format/DimensionFormattingContainer";
import { canApplyFormat } from "../../common/fields/format/dimensionFormattingHelper";

const FormatContainer = () => {
  const { fieldsArea } = useFieldsStateContext();

  const supportingFormattingMeasures = useMemo(() => fieldsArea.values.filter(isMeasure), [fieldsArea.values]);
  const dimensions = useMemo(() => fieldsArea.values.filter(isDimensionBased), [fieldsArea.values]);
  const supportingFormattingDimensions = useMemo(() => dimensions.filter(canApplyFormat), [dimensions]);

  const handleMeasureChange = useCallback(
    (measure: ReportField, changes: Partial<FieldConfiguration>) => {
      const field = fieldsArea.values.find((f) => f.config.guid === measure.config.guid);
      if (isMeasure(field)) {
        fieldsArea.updateItemConfig(field, changes);
      }
    },
    [fieldsArea]
  );

  const handleDimensionChange = useCallback(
    (dimension: ReportField, changes: Partial<FieldConfiguration>) => {
      const field = fieldsArea.values.find((f) => f.config.guid === dimension.config.guid);
      if (isDimensionBased(field)) {
        fieldsArea.updateItemConfig(field, changes);
      }
    },
    [fieldsArea]
  );

  return (
    <ScrollableFlexContainer scrollContainerSx={{ px: "1.3rem" }}>
      <Stack gap={1}>
        <TextFieldContainer fields={dimensions} onUpdateConfig={handleDimensionChange} />
        <DimensionFormattingContainer
          dimensions={supportingFormattingDimensions}
          onUpdateConfig={handleDimensionChange}
        />
        <MeasureFieldsContainer measures={supportingFormattingMeasures} onChange={handleMeasureChange} />
      </Stack>
    </ScrollableFlexContainer>
  );
};

export default FormatContainer;
