import { Typography } from "@mui/material";
import { PropsWithChildren } from "react";

interface Props {
  captionVariant?: "caption" | "body1";
  captionColor?: string;
  text: string | undefined;
}

const PrimaryCaption = ({ text, captionVariant = "caption", captionColor, children }: PropsWithChildren<Props>) => {
  return (
    <Typography
      variant={captionVariant}
      component={"p"}
      noWrap
      sx={{ color: (theme) => captionColor || theme.palette.text.primary }}
    >
      {text}
      {children}
    </Typography>
  );
};

export default PrimaryCaption;
