import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import { Box, IconButton, Typography } from "@mui/material";
import TooltipWrapper from "../../../../shared/components/TooltipWrapper";
import { CustomLabelData } from "./CustomLabelData";

interface ViewCustomLabelProps extends CustomLabelData {
  onRenameClick: () => void;
  readonly?: boolean;
}
export default function ViewCustomLabel({
  customLabelValue,
  originalName,
  onRenameClick,
  readonly,
}: ViewCustomLabelProps) {
  return (
    <>
      <Box flex={1} overflow={"hidden"}>
        <Typography variant="subtitle1" noWrap>
          {customLabelValue ? customLabelValue : originalName}
        </Typography>
        {customLabelValue && <Typography variant="caption">Default name - {originalName}</Typography>}
      </Box>
      {readonly !== true && (
        <TooltipWrapper title="Rename" placement="bottom">
          <IconButton onClick={onRenameClick}>
            <DriveFileRenameOutlineIcon sx={(theme) => ({ color: theme.palette.secondary.light })} />
          </IconButton>
        </TooltipWrapper>
      )}
    </>
  );
}
