import { Box } from "@mui/material";
import { useMemo } from "react";
import PrimaryCaption from "../field-captions/PrimaryCaption";
import { FieldItemProps } from "../types/areaFiledItem.types";
import { getDefaultCaption } from "./AreaFieldsHelper";

const FieldItemCaptions = <T,>({
  field,
  formatCaption,
  disableEqualitySelection,
}: Pick<FieldItemProps<T>, "field" | "formatCaption" | "disableEqualitySelection">) => {
  const defaultCaption = useMemo(() => getDefaultCaption(field), [field]);
  const customCaption = formatCaption && formatCaption(field, undefined, disableEqualitySelection);

  if (customCaption) {
    return customCaption;
  }

  return (
    <Box px={0.8}>
      <PrimaryCaption text={defaultCaption} />
    </Box>
  );
};

export default FieldItemCaptions;
