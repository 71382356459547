import { Typography } from "@mui/material";

interface Props {
  caption: string;
  color?: string;
}

const SubCaption = ({ caption, color }: Props) => {
  return (
    <Typography
      variant="caption"
      textAlign={"end"}
      noWrap
      sx={{ color: (theme) => color || theme.palette.primary.main }}
    >
      {caption}
    </Typography>
  );
};

export default SubCaption;
