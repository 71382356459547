import { Box, Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import {
  DimensionField,
  FieldConfiguration,
  MeasureDateRange,
  ReportField,
} from "../../../../../shared/reporting/api/biClient.types";
import { selectDimensions } from "../../../../store/metaDataSlice";
import TypographyWithTooltip from "../../../common/TypographyWithTooltip";
import DateRanges from "../../common/fields/measures/DateRanges";
import { ConditionField } from "../../Types";
import { dateRangeToShortString } from "../../utils/dateRangeToString";
import { isMeasureConfigurationValid } from "../../utils/isConfigurationValid";

interface Props {
  measure: ReportField;
  fields: DimensionField[];
  conditions: ConditionField[];
  onClose: () => void;
  saveChanges: (changes: Partial<FieldConfiguration>, causeClosing: boolean) => void;
}

export default function PeriodMenuItem({ measure, fields, conditions, onClose, saveChanges }: Props) {
  const updateDateRangeType = React.useCallback(
    (newDateRangeType: MeasureDateRange) => {
      if (measure.config.dateRange === newDateRangeType) {
        onClose();
      } else {
        saveChanges({ dateRange: newDateRangeType }, false);
      }
    },
    [measure, onClose, saveChanges]
  );

  return (
    <Grid container>
      <DateRanges
        dateRange={measure.config.dateRange ?? MeasureDateRange.EndingBalance}
        onChange={updateDateRangeType}
      />
      <ReferenceDateField measure={measure} fields={fields} conditions={conditions} />
    </Grid>
  );
}

function ReferenceDateField({ measure, fields, conditions }: Pick<Props, "measure" | "fields" | "conditions">) {
  const dimensions = useSelector(selectDimensions);

  const measureValidity = React.useMemo(
    () => isMeasureConfigurationValid(conditions, fields, dimensions, measure),
    [conditions, fields, dimensions, measure]
  );

  const isReferenceDateApplicable = React.useMemo(() => {
    return (
      (measureValidity.valid && measureValidity.referenceDateField !== undefined) ||
      measure.config.dateRange !== MeasureDateRange.NetChange
    );
  }, [measureValidity, measure]);

  if (!isReferenceDateApplicable) return <></>;

  return (
    <Box sx={{ mt: -1.2 }}>
      <TypographyWithTooltip
        tooltipText={`Date Dimension that is used for calculating ${dateRangeToShortString(
          measure.config.dateRange
        )} and the one is determined by the Date Dimension Fields order in the Conditions: first Date Dimension is considered as a Date field to use.`}
        label={`by ${measureValidity.referenceDateField?.caption}`}
        typographyProps={{ variant: "caption", sx: { color: "primary.main" } }}
      />
    </Box>
  );
}
