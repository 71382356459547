import { ListItemIcon, MenuItem, Typography } from "@mui/material";
import { useMemo } from "react";
import HorizontalFill from "../../../../../shared/components/HorizontalFill";
import { IFormulaVariable } from "./utilities/variables";

interface Props {
  searchText: string;
  suggestion: IFormulaVariable;
  selected: boolean;
  onSelect: () => void;
}

export const IntellisenseItem = ({ searchText, selected, suggestion, onSelect: onClick }: Props) => {
  const splitTextItems = useMemo(() => {
    const result: { text: string; highlight: boolean }[] = [];
    const index = suggestion.caption.toLowerCase().indexOf(searchText);

    if (index > 0) {
      const substr = suggestion.caption.substring(0, index);
      result.push({ text: substr, highlight: false });
    }
    const substr = suggestion.caption.substring(index, index + searchText.length);
    result.push({ text: substr, highlight: true });
    if ((index + searchText.length, suggestion.caption.length - 1)) {
      const substr = suggestion.caption.substring(index + searchText.length, suggestion.caption.length);
      result.push({ text: substr, highlight: false });
    }
    return result;
  }, [searchText, suggestion]);

  return (
    <MenuItem key={suggestion.key} sx={{ py: 0.5, pl: 0, pr: 1 }} selected={selected} onMouseDown={onClick}>
      <ListItemIcon
        sx={(theme) => ({ minWidth: "20px", justifyContent: "center", color: theme.palette.secondary.light })}
      >
        {suggestion.icon()}
      </ListItemIcon>
      {splitTextItems.map((item) => (
        <Typography
          key={item.text}
          variant="caption"
          sx={{
            whiteSpace: "nowrap",
            bgcolor: item.highlight ? "rgba(255,215,0,0.5)" : "none",
          }}
        >
          {item.text.replace(/\s/g, "\xa0")}
        </Typography>
      ))}
      <HorizontalFill />
      <Typography variant="caption" sx={{ color: (t) => t.palette.secondary.light, pl: 1 }}>
        {suggestion.category}
      </Typography>
    </MenuItem>
  );
};

export default IntellisenseItem;
