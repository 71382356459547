import { Box } from "@mui/material";
import React from "react";
import {
  DimensionField,
  FieldConfiguration,
  ItemDataType,
  ReportField,
} from "../../../../../shared/reporting/api/biClient.types";
import ShowLogoIconComponent from "../../common/fields/format/ShowLogoIconComponent";
import TextField from "../../common/fields/format/TextField";
import { isFieldSupportedLogoIcon } from "../../common/fields/format/isFieldSupportedLogoIcon";
import { isDimension } from "../../utils/fieldsHelper";

const getTextFields = (fields: ReportField[]): DimensionField[] => {
  return fields.filter(isDimension).filter((v) => v.meta.type === ItemDataType.General);
};

interface Props {
  fields: DimensionField[];
  onUpdateConfig: (field: ReportField, changes: Partial<FieldConfiguration>) => void;
}

export default function TextFieldContainer({ fields, onUpdateConfig }: Props) {
  const textFields = React.useMemo(() => getTextFields(fields).filter(isFieldSupportedLogoIcon), [fields]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: ".5rem" }}>
      {textFields.map((field) => (
        <TextField key={field.meta.name} field={field} onUpdateConfig={onUpdateConfig}>
          <ShowLogoIconComponent field={field} onUpdateConfig={onUpdateConfig} />
        </TextField>
      ))}
    </Box>
  );
}
